import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewRef
} from '@angular/core';
import { ButtonsPanelComponent } from '@kuki/global/features/buttons-panel/buttons-panel.component';
import { getButtonsTemplates } from '@kuki/global/features/buttons-panel/buttons-templates';
import { MediaPlayerV2Service } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.service';
import { UiHelper } from '@kuki/global/shared/helpers/ui.helper';
import { AutoScrollContainerComponent } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.component';
import { ImageLinkFactoryService } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.service';
import { MediaService } from '@kuki/global/shared/modules/media/media.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { TagService } from '@kuki/global/shared/services/tag.service';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { ActionButton } from '@kuki/global/shared/types/button';
import { ActionKey, AndroidTVKeys, ArrisKeys, CommonKeys, GroupKeys, TizenKeys } from '@kuki/global/shared/types/controller/keymap';
import { Actions, MediaTypes, Tags } from '@kuki/global/shared/types/enum';
import { AudioTrack, BroadcastGap, BroadcastGapGroup, PortalSettings, SubtitleTrack } from '@kuki/global/shared/types/general';
import { Media } from '@kuki/global/shared/types/media';
import { MediaPlayerTypes } from '@kuki/global/shared/types/media-player';
import { MediaTile } from '@kuki/global/shared/types/tile';
import { hal } from '@kuki/platforms/hal';
import { TranslateService } from '@ngx-translate/core';
import { from, fromEvent, merge, Observable, of, ReplaySubject, Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, tap, throttleTime } from 'rxjs/operators';
import { SettingsParsed } from '../../shared/types/settings';

@Component({
    selector: 'app-osd',
    templateUrl: './osd.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OsdComponent implements OnInit, OnChanges, OnDestroy {

    @Input() ident: string = 'osd';
    @Input() tile: MediaTile;
    @Input() disabled: boolean = false;

    @Output() toggleTeletext: EventEmitter<void> = new EventEmitter<void>();
    @Output() contextMenuToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() seek: EventEmitter<number> = new EventEmitter<number>();
    @Output() playPauseToggle: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('osdProgressBarEl', { static: false }) osdProgressBarEl: ElementRef<HTMLElement>;
    @ViewChild('buttonsPanelActions', { static: false }) buttonsPanelActionsComponent: ButtonsPanelComponent;
    @ViewChild('buttonsPanelAudio', { static: false }) buttonsPanelAudioComponent: ButtonsPanelComponent;
    @ViewChild('buttonsPanelAudioDD', { static: false }) buttonsPanelAudioDDComponent: ButtonsPanelComponent;
    @ViewChild('buttonsPanelSubtitle', { static: false }) buttonsPanelSubtitleComponent: ButtonsPanelComponent;
    @ViewChild('autoScrollLabel', { static: false }) autoScrollLabelComponent: AutoScrollContainerComponent;
    @ViewChild('autoScrollDescription', { static: false }) autoScrollDescriptionComponent: AutoScrollContainerComponent;

    private readonly DEBUG_INTERVAL_VALUE: number = 2000;
    private PROGRESS_BAR_WIDTH = this.coreService.isTvPlatform() ?
        UiHelper.getColumnsWidth(36) : UiHelper.getColumnsVwWidth(10, false);
    private PROGRESS_BAR_OFFSET_CENTER = this.coreService.isTvPlatform() ?
        UiHelper.getColumnsWidth(10) / 2 : UiHelper.getColumnsVwWidth(3) / 2;

    private buttonsTemplates = getButtonsTemplates(this.translateService);
    public media: Media;
    public contextMenuOpened: boolean;
    public actionButtons: Array<ActionButton>;
    public audioButtons: Array<ActionButton>;
    public audioDDButtons: Array<ActionButton>;
    public subtitleButtons: Array<ActionButton>;
    private actionSubscription: Subscription;
    private subscription: SubscriptionObject = {};
    public liveProgress: number;
    public cursorProgress: number;
    public cursorProgressTime: number;
    public cursorProgressAbsTime: number;
    public watchingProgress: number;
    public watchingProgressTime: number;
    public watchingProgressAbsTime: number;
    private mouseMoving: ReplaySubject<number> = new ReplaySubject<number>(1);
    private mouseProgress: number;
    private mouseInsideProgressBar: boolean;
    private mouseInsideProgressBarDown: boolean = false;
    public debug: string;
    private portalSettings: PortalSettings;
    private settings: SettingsParsed;
    private activeButtonsPanelIndex: number = 0;
    private buttonsPanelMap: Array<ButtonsPanelComponent> = [];
    private activeAudioTrack: AudioTrack;
    private activeSubtitleTrack: SubtitleTrack;
    public selectedAudioButtons: Array<ActionButton>;
    public selectedAudioDDButtons: Array<ActionButton>;
    public selectedSubtitleButtons: Array<ActionButton>;
    public preventClickEvent: boolean;
    public isFullscreen: boolean;
    public broadcastGapGroups: Array<BroadcastGapGroup>;
    public activeBroadcastGapGroup: BroadcastGapGroup;
    private broadcastGapIdent: string = this.ident + '-broadcast-gap';
    public broadcastGapSkipDelay: number;

    public propagateActionKeys: Array<ActionKey> = [
        CommonKeys.GRP_BACK,
        CommonKeys.GRP_REC,
        CommonKeys.CH_UP,
        CommonKeys.CH_DOWN,
        CommonKeys.PLAY,
        CommonKeys.PAUSE,
        CommonKeys.STOP,
        CommonKeys.GRP_INFO,
        CommonKeys.GRP_TV,
        CommonKeys.REWIND,
        CommonKeys.FORWARD,
        CommonKeys.PREV,
        CommonKeys.NEXT,
        ...GroupKeys.GRP_NUMBER
    ];

    public propagateActionKeysHold: Array<ActionKey> = [
        CommonKeys.REWIND,
        CommonKeys.FORWARD,
    ];

    public chromecastAvailable$: Observable<boolean> = this.mediaPlayerV2Service.chromecastAvailable$;
    public chromecastConnected$: Observable<boolean> = this.mediaPlayerV2Service.chromecastConnected$;
    public airplayAvailable$: Observable<boolean> = this.mediaPlayerV2Service.airplayAvailable$;
    public airplayConnected$: Observable<boolean> = this.mediaPlayerV2Service.airplayConnected$;
    private seekInterval: any;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private portalSettingsService: PortalSettingsService,
        private settingsService: SettingsService,
        private tileService: TileService,
        private mediaPlayerV2Service: MediaPlayerV2Service,
        private navigationService: NavigationService,
        private controllerService: ControllerService,
        private imageLinkFactoryService: ImageLinkFactoryService,
        private mediaService: MediaService,
        private coreService: CoreService,
        private tagService: TagService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private ngZone: NgZone) {
    }

    ngOnInit() {
        this.portalSettings = this.portalSettingsService.getPortalSettings();
        this.settings = this.settingsService.getParsedSettings();
        this.broadcastGapSkipDelay = this.settings.broadcastGapSkipAuto ? 3000 : 5000;
        this.watchGestures();
        this.initTimers();
        this.initActiveBroadcastGap();
        if (this.settingsService.getParsedSettingsValue('debug')) {
            this.showDebug();
        }
        this.broadcastGapGroups = this.mediaPlayerV2Service.getChannelBroadcastGapsIntervals();
        this.subscription.activeAudioTrack = this.mediaPlayerV2Service.trackActivated$.subscribe(() => {
            this.activeAudioTrack = this.mediaPlayerV2Service.getActiveAudioTrack();
            this.activeSubtitleTrack = this.mediaPlayerV2Service.getActiveSubtitleTrack();
            this.generateSelectedButtons();
            this.changeDetectorRef.detectChanges();
        });

        this.watchMouse();
        this.watchFullscreen();
        this.watchChanges();
        this.watchBroadcastGaps();
        if (this.coreService.isWebPlatform() || this.coreService.isMobilePlatform()) {
            this.watchResize();
        }

        // platform specific propagations
        if (hal.platform === 'TV.ANDROID') {
            this.propagateActionKeys.push(AndroidTVKeys.DOUBLE_VOLUME);
        } else if (hal.platform === 'TV.TIZEN') {
            this.propagateActionKeys.push(TizenKeys.PAUSEPLAY_COMBO);
        } else if (hal.platform === 'TV.ARRIS') {
            this.propagateActionKeys.push(ArrisKeys.TXT, ArrisKeys.PAUSEPLAY_COMBO);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('tile')) {
            SOM.clearSubscriptions(this.subscription.fetchDetail);
            this.subscription.fetchDetail = this.fetchDetail().subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    public getGapsLeft(broadcastGap: BroadcastGapGroup) {
        return this.tileService.calculateProgressPer(this.tile, broadcastGap.datetimeFrom);
    }

    public getGapsWidth(broadcastGap: BroadcastGapGroup) {
        return this.tileService.calculateProgressPer(this.tile, broadcastGap.datetimeTo) -
            this.tileService.calculateProgressPer(this.tile, broadcastGap.datetimeFrom);
    }

    public openContextMenu() {
        if (this.disabled) {
            return;
        }
        this.contextMenuOpened = true;
        this.initActionButtons();
        this.watchTrackChanges();
        this.registerControls();
        this.activateButtonsPanel();
        this.changeDetectorRef.detectChanges();
        this.contextMenuToggled.next(true);
    }

    public closeContextMenu(webOnly = false) {
        if (webOnly && hal.platform !== 'WEB') {
            return;
        }
        this.contextMenuOpened = false;
        SOM.clearSubscriptions(this.subscription.tracksUpdated);
        this.unregisterControls();
        this.actionButtons = [];
        this.buttonsPanelMap = [];
        this.changeDetectorRef.detectChanges();
        this.contextMenuToggled.next(false);
    }

    public toggleContextMenu() {
        if (this.contextMenuOpened) {
            this.closeContextMenu();
            return false;
        } else {
            this.openContextMenu();
            return true;
        }
    }

    public refreshActions() {
        this.initActionButtons();
    }

    private watchTrackChanges() {
        SOM.clearSubscriptions(this.subscription.tracksUpdated);
        this.subscription.tracksUpdated = this.mediaPlayerV2Service.tracksUpdated$
            .subscribe(() => {
                this.initTrackButtons();
                this.generateSelectedButtons();
                this.changeDetectorRef.detectChanges();
                this.generateButtonsPanelMap();
            });
    }

    private watchChanges() {
        this.subscription.onRecordChange = merge(this.mediaService.onRecordChanged$, this.mediaPlayerV2Service.onPause$)
            .subscribe(() => {
                this.initActionButtons();
            });
    }

    private initActionButtons() {
        this.actionButtons = [];
        if (!this.mediaPlayerV2Service.isTimeshiftDisabled()) {
            if (this.isPlaying()) {
                this.actionButtons.push(this.buttonsTemplates.PAUSE);
            } else {
                this.actionButtons.push(this.buttonsTemplates.PLAY);
            }
            this.actionButtons.push(this.buttonsTemplates.PLAY_START_OVER);
            if (this.mediaPlayerV2Service.getMediaPlayerType() === MediaPlayerTypes.CHANNEL && !this.isLive()) {
                this.actionButtons.push(this.buttonsTemplates.PLAY_LIVE);
            }
        }
        if (!this.coreService.isProfileActive() || this.coreService.isProfileTypeActive('classic', 'senior')) {
            // TODO: use buttons without text on mobile for support all buttons
            if (!this.coreService.isMobilePlatform() && this.tileService.showMediaDetail(this.tile)) {
                this.actionButtons.push(this.buttonsTemplates.SHOW_MEDIA_DETAIL);
            }
            if (this.tileService.showRecordAction(this.tile)) {
                this.actionButtons.push({
                    ...this.buttonsTemplates.RECORD,
                    label: this.tileService.tagExists(this.tile, Tags.REC) ?
                        this.translateService.instant('GENERAL.ACTION.DELETE') : this.buttonsTemplates.RECORD.label
                });
            }
            const channel = this.mediaPlayerV2Service.getChannel();
            if (channel && channel.swttxIdent) {
                this.actionButtons.push(this.buttonsTemplates.TELETEXT);
            }
            if (this.tileService.showTeleportAction(this.tile)) {
                this.actionButtons.push({
                    ...this.buttonsTemplates.OPEN_TELEPORT_TO
                });
            }
        }
        if (this.coreService.canShare() && this.coreService.isMobilePlatform()) {
            this.actionButtons.push({
                ...this.buttonsTemplates.SHARE
            });
        }
        this.changeDetectorRef.detectChanges();
    }

    private initTrackButtons() {
        const audioTracks = this.mediaPlayerV2Service.getAudioTracks();
        const subtitleTracks = this.mediaPlayerV2Service.getSubtitleTracks();
        if (audioTracks) {
            const languagesAudio = this.portalSettingsService.getLanguagesAudio();
            const buttons = audioTracks.map(audioTrack => {
                const languageLabel = languagesAudio.find(item => item.value === audioTrack.language);
                return {
                    label: languageLabel ? languageLabel.text : audioTrack.label,
                    action: Actions.CHANGE_AUDIO,
                    data: audioTrack
                };
            });
            // 2.0 or others
            this.audioButtons = buttons
                .filter(button => !button.data.layout || button.data.layout === '2.0');
            this.audioDDButtons = buttons
                .filter(button => button.data.layout === '5.1');
        }
        if (!this.coreService.isProfileActive() || this.coreService.isProfileTypeActive('classic', 'senior')) {
            if (subtitleTracks) {
                const languagesSubtitles = this.portalSettingsService.getLanguagesSubtitles();
                this.subtitleButtons = subtitleTracks.map(subtitleTrack => {
                    const languageLabel = languagesSubtitles.find(item => item.value === subtitleTrack.language);
                    return {
                        label: languageLabel ? languageLabel.text : subtitleTrack.label,
                        action: Actions.CHANGE_SUBTITLES,
                        data: subtitleTrack
                    };
                });
                this.subtitleButtons.push({
                    label: this.translateService.instant('GENERAL.ACTION.DISABLE_SUBTITLES'),
                    action: Actions.CHANGE_SUBTITLES
                });
            }
        }
    }

    public get endTime() {
        const overlap = this.tileService.getOverlap(this.tile);
        return this.tile.raw.duration + overlap.after;
    }

    public async onActionButton(actionButton: ActionButton) {
        let action$;
        switch (actionButton.action) {
            case Actions.PLAY:
                if (this.mediaPlayerV2Service.isPaused()) {
                    action$ = this.mediaPlayerV2Service.unpause();
                } else if (this.mediaPlayerV2Service.isStopped()) {
                    action$ = this.mediaPlayerV2Service.refreshPlayer();
                }
                break;
            case Actions.PAUSE:
                if (this.mediaPlayerV2Service.isTimeshiftDisabled()) {
                    return;
                }
                action$ = this.mediaPlayerV2Service.pause();
                break;
            case Actions.PLAY_START_OVER:
                action$ = this.mediaPlayerV2Service.startOver();
                break;
            case Actions.PLAY_LIVE:
                action$ = this.mediaPlayerV2Service.playLive(this.mediaPlayerV2Service.getChannel().id);
                break;
            case Actions.RECORD:
                action$ = this.tileService.record(this.tile).pipe(tap((state) => {
                    if (this.tile.mediaType === MediaTypes.NPVR && state === -1) {
                        this.navigationService.navigateHome();
                        return;
                    }
                }));
                break;
            case Actions.TELETEXT:
                this.toggleTeletext.emit();
                break;
            case Actions.OPEN_TELEPORT_TO:
                const activeAudioTrack = this.mediaPlayerV2Service.getActiveAudioTrack();
                const activeSubtitleTrack = this.mediaPlayerV2Service.getActiveSubtitleTrack();
                action$ = this.tileService.openTeleportToModal(this.tile, {
                    audioTrack: activeAudioTrack ? { language: activeAudioTrack.language, layout: activeAudioTrack.layout } : undefined,
                    subtitleTrack: activeSubtitleTrack ? { language: activeSubtitleTrack.language } : undefined
                });
                break;
            case Actions.SHOW_MEDIA_DETAIL:
                action$ = from(this.tileService.openMediaDetail(this.tile));
                break;
            case Actions.SHARE:
                this.mediaPlayerV2Service.refreshUrl().subscribe(() => {
                    this.coreService.share();
                });
                break;
        }
        if (action$) {
            SOM.clearSubscriptions(this.actionSubscription);
            this.actionSubscription = action$.subscribe(() => {
                if (!(this.changeDetectorRef as ViewRef).destroyed) {
                    this.initActionButtons();
                }
            });
        }
    }

    public onChangeAudio(actionButton: ActionButton) {
        const audioTrack = actionButton.data as AudioTrack;
        if (audioTrack) {
            this.mediaPlayerV2Service.activateAudioTrack(audioTrack.id);
        }
    }

    public onChangeSubtitle(actionButton: ActionButton) {
        if (actionButton.data) {
            const subtitleTrack = actionButton.data as SubtitleTrack;
            this.mediaPlayerV2Service.activateSubtitleTrack(subtitleTrack.id);
        } else {
            this.mediaPlayerV2Service.activateSubtitleTrack(undefined);
        }
    }

    public showCastActions() {
        return !this.contextMenuOpened || this.coreService.isWebPlatform();
    }

    private onContextMenuVerNav(dir: number) {
        if (dir < 0 && this.activeButtonsPanelIndex === 0) {
            return;
        }
        if (dir > 0 && this.activeButtonsPanelIndex === this.buttonsPanelMap.length - 1) {
            return;
        }
        this.activateButtonsPanel(this.activeButtonsPanelIndex + dir);
    }

    private initTimers() {
        this.ngZone.runOutsideAngular(() => {
            this.subscription.playerTimer = this.mediaPlayerV2Service.playerTimer$.subscribe(() => {
                const liveTime = this.mediaPlayerV2Service.getLiveTime();
                const watchingTime = this.mediaPlayerV2Service.getWatchingTime();
                this.liveProgress = this.tileService.calculateProgressPer(this.tile, liveTime);
                this.watchingProgress = this.tileService.calculateProgressPer(this.tile, watchingTime);
                this.watchingProgressTime = this.tileService.calculateProgressTime(this.tile, watchingTime, false);
                this.watchingProgressAbsTime = this.tileService.calculateProgressTime(this.tile, watchingTime);
                if (!this.mediaPlayerV2Service.isSeeking() && !this.mouseInsideProgressBar) {
                    const cursorTime = this.mediaPlayerV2Service.getCursorTime();
                    this.cursorProgress = this.tileService.calculateProgressPer(this.tile, cursorTime);
                    this.cursorProgressTime = this.tileService.calculateProgressTime(this.tile, cursorTime, false);
                    this.cursorProgressAbsTime = this.tileService.calculateProgressTime(this.tile, cursorTime);
                }
                this.changeDetectorRef.detectChanges();
            });

            this.subscription.seeking = this.mediaPlayerV2Service.seeking$.subscribe(() => {
                const cursorTime = this.mediaPlayerV2Service.getCursorTime();
                this.cursorProgress = this.tileService.calculateProgressPer(this.tile, cursorTime);
                this.cursorProgressTime = this.tileService.calculateProgressTime(this.tile, cursorTime, false);
                this.cursorProgressAbsTime = this.tileService.calculateProgressTime(this.tile, cursorTime);
                this.changeDetectorRef.detectChanges();
            });

            this.subscription.mouseMove = this.mouseMoving.subscribe((value => {
                this.mouseProgress = value;
                if (this.mouseInsideProgressBarDown) {
                    this.cursorProgress = value;
                }
                this.cursorProgressTime = this.tileService.calculateProgressTimeFromPer(this.tile, value, false);
                this.cursorProgressAbsTime = this.tileService.calculateProgressTimeFromPer(this.tile, value);
            }));
        });
    }

    private showDebug() {
        if (this.subscription.debugInterval) {
            SOM.clearSubscriptions(this.subscription.debugInterval);
        }
        this.ngZone.runOutsideAngular(() => {
            this.subscription.debugInterval = timer(0, this.DEBUG_INTERVAL_VALUE).pipe(
                switchMap(() => this.mediaPlayerV2Service.getPlayerDebug())
            ).subscribe((debug) => {
                this.debug = debug;
                this.changeDetectorRef.detectChanges();
            });
        });
    }

    private getPreviewLeft(posPer: number) {
        // TODO: make it general as one function without if statements
        if ([ 'WEB', 'MOBILE.ANDROID', 'MOBILE.IOS' ].indexOf(hal.platform) >= 0) {
            const progressBarOffsetCenter = UiHelper.resolutionWeb === 'xxl' ?
                UiHelper.getVw(this.PROGRESS_BAR_OFFSET_CENTER, UiHelper.windowWidth) : this.PROGRESS_BAR_OFFSET_CENTER;
            const posPx = UiHelper.getValueFromPer(posPer, this.PROGRESS_BAR_WIDTH);
            const posLeft = posPx - progressBarOffsetCenter;
            const posRight = posPx + progressBarOffsetCenter;
            if (posLeft < 0) {
                return UiHelper.getPerFromValue(posPx - posLeft, this.PROGRESS_BAR_WIDTH);
            }
            if (posRight > this.PROGRESS_BAR_WIDTH) {
                return UiHelper.getPerFromValue(this.PROGRESS_BAR_WIDTH - (posRight - posPx), this.PROGRESS_BAR_WIDTH);
            }
            return posPer;
        } else {
            const posPx = UiHelper.getValueFromPer(posPer, this.PROGRESS_BAR_WIDTH);
            const posLeft = posPx - this.PROGRESS_BAR_OFFSET_CENTER;
            const posRight = posPx + this.PROGRESS_BAR_OFFSET_CENTER;
            if (posLeft < 0) {
                return UiHelper.getPerFromValue(posPx - posLeft - 8, this.PROGRESS_BAR_WIDTH);
            }
            if (posRight > this.PROGRESS_BAR_WIDTH) {
                return UiHelper.getPerFromValue(this.PROGRESS_BAR_WIDTH - (posRight - posPx) + 6, this.PROGRESS_BAR_WIDTH);
            }
            return posPer;
        }
    }

    public getPreviewBoxStyle() {
        const signIdent = this.mediaPlayerV2Service.getStoredSignIdent();
        const hevc = this.mediaPlayerV2Service.getHevc() ? 'hevc.' : '';
        const mod = this.portalSettings.thumbnailInterval;
        const imageName = this.cursorProgressAbsTime - (this.cursorProgressAbsTime % mod);
        const generatedUrl =
            `${ this.imageLinkFactoryService.thumbnailBase }${ signIdent }/thumbnail/${ hevc }${ imageName }.jpg`;
        return {
            left: this.getPreviewLeft(this.getProgress()) + '%',
            backgroundImage: `url(${ generatedUrl })`
        };
    }

    public progressTimeVisible(posPer: number, progressTime: number) {
        const posPx = UiHelper.getValueFromPer(posPer, this.PROGRESS_BAR_WIDTH);
        const progressMinutes = Math.floor(progressTime / 60000);
        const endTimeMinutes = Math.floor(this.endTime / 60000);
        const watchedTimeOffset = progressMinutes > 60 ? UiHelper.getProperty(120) : UiHelper.getProperty(80);
        const totalTimeWidth = endTimeMinutes > 60 ? UiHelper.getProperty(240) : UiHelper.getProperty(160);
        return posPx + watchedTimeOffset <= this.PROGRESS_BAR_WIDTH - totalTimeWidth;
    }

    public getProgress() {
        return this.mouseInsideProgressBar ? this.mouseProgress : this.cursorProgress;
    }

    public hasPreview() {
        if (this.mediaPlayerV2Service.getMediaPlayerType() === MediaPlayerTypes.CHANNEL) {
            const channel = this.mediaPlayerV2Service.getChannel();
            if (channel?.streamType === 'radio') {
                return false;
            }
        }
        if (this.cursorProgressAbsTime >= Date.now() - 1000) {
            return false;
        }
        const signIdent = this.mediaPlayerV2Service.getStoredSignIdent();
        return signIdent && this.cursorProgressAbsTime;
    }

    public isSeeking() {
        return this.mediaPlayerV2Service.isSeeking() || this.mouseInsideProgressBar;
    }

    public isPlaying() {
        return !this.mediaPlayerV2Service.isPaused() && !this.mediaPlayerV2Service.isStopped();
    }

    public isPaused() {
        return this.mediaPlayerV2Service.isPaused();
    }

    public isLive() {
        return this.mediaPlayerV2Service.isLive();
    }

    public isLocked() {
        return this.mediaPlayerV2Service.isLocked();
    }

    private fetchDetail() {
        if (!this.tile) {
            return of(undefined);
        }
        const mediaStorage = this.mediaService.getMediaStorage(this.tile.guid);
        const obs$ = mediaStorage ? of(mediaStorage) : this.mediaService.getMedia(this.tile.id, this.tile.mediaType);
        return obs$.pipe(tap(media => {
            this.resetAutoScrollDescription();
            this.media = media;
            this.media.tile.state = this.tileService.detectTileState(this.media.tile);
            if (this.media.genre && this.media.genre.length > 1) {
                this.media.genre = [ ...this.media.genre.slice(0, 1), '...' ];
            }
            this.tagService.storeTagsFromTile(this.media.tile);
            this.mediaService.setMediaStorage(this.tile.guid, this.media);
        }));
    }

    private generateButtonsPanelMap() {
        this.buttonsPanelMap = [];
        if (this.actionButtons && this.actionButtons.length) {
            this.buttonsPanelMap.push(this.buttonsPanelActionsComponent);
        }
        if (this.audioButtons && this.audioButtons.length) {
            this.buttonsPanelMap.push(this.buttonsPanelAudioComponent);
        }
        if (this.audioDDButtons && this.audioDDButtons.length) {
            this.buttonsPanelMap.push(this.buttonsPanelAudioDDComponent);
        }
        if (this.subtitleButtons && this.subtitleButtons.length) {
            this.buttonsPanelMap.push(this.buttonsPanelSubtitleComponent);
        }
    }

    private generateSelectedButtons() {
        if (this.audioButtons) {
            this.selectedAudioButtons = this.audioButtons.filter(button => this.isAudioButtonSelected(button));
        }
        if (this.audioDDButtons) {
            this.selectedAudioDDButtons = this.audioDDButtons.filter(button => this.isAudioButtonSelected(button));
        }
        if (this.subtitleButtons) {
            this.selectedSubtitleButtons = this.subtitleButtons.filter(button => this.isSubtitleButtonSelected(button));
        }
    }

    private activateButtonsPanel(index: number = this.activeButtonsPanelIndex) {
        if (this.buttonsPanelMap[ this.activeButtonsPanelIndex ]) {
            this.buttonsPanelMap[ this.activeButtonsPanelIndex ].deactivate();
        }
        if (this.buttonsPanelMap[ index ]) {
            this.buttonsPanelMap[ index ].activate();
            this.activeButtonsPanelIndex = index;
        }
    }

    public isAudioButtonSelected(button: ActionButton) {
        return button.data && this.activeAudioTrack && this.activeAudioTrack.id === button.data.id;
    }

    public isSubtitleButtonSelected(button: ActionButton) {
        if (!button.data && !this.activeSubtitleTrack) {
            return true;
        }
        return button.data && this.activeSubtitleTrack && this.activeSubtitleTrack.id === button.data.id;
    }

    public resetAutoScrollLabel() {
        if (this.autoScrollLabelComponent) {
            this.autoScrollLabelComponent.reset();
        }
    }

    public resetAutoScrollDescription() {
        if (this.autoScrollDescriptionComponent) {
            this.autoScrollDescriptionComponent.reset();
        }
    }

    private registerControls() {
        this.controllerService.registerActionKey(CommonKeys.UP, this.ident, () => this.onContextMenuVerNav(-1));
        this.controllerService.registerActionKey(CommonKeys.DOWN, this.ident, () => this.onContextMenuVerNav(1));
        this.controllerService.propagateActionKeys(this.propagateActionKeys, this.ident);
        this.controllerService.propagateActionKeys(this.propagateActionKeysHold, this.ident, 'h');
    }

    private unregisterControls() {
        this.controllerService.unregisterStackLevel(this.ident);
    }

    /* Mouse section */
    private watchMouse() {
        this.ngZone.runOutsideAngular(() => {
            this.subscription.onMove = merge(
                hal.platform !== 'WEB' ? of(undefined) : fromEvent(document, 'mousemove'),
                fromEvent(document, 'touchmove', { passive: true })
            ).subscribe(event => {
                if (!event) {
                    return;
                }
                if (this.isTouchEvent(event)) {
                    this.onMouseMove((event as TouchEvent).touches[ 0 ], true);
                } else {
                    this.onMouseMove(event as MouseEvent);
                }
                this.changeDetectorRef.detectChanges();
            });

            this.subscription.onDown = merge(
                fromEvent(document, 'mousedown'),
                fromEvent(document, 'touchstart')
            ).pipe(throttleTime(200)).subscribe(event => {
                if (this.isTouchEvent(event)) {
                    this.onMouseDown((event as TouchEvent).touches[ 0 ]);
                } else {
                    this.onMouseDown(event as MouseEvent);
                }
                this.changeDetectorRef.detectChanges();
            });

            this.subscription.onUp = merge(
                fromEvent(document, 'mouseup'),
                fromEvent(document, 'touchend')
            ).pipe(throttleTime(200)).subscribe(event => {
                this.onMouseUp();
                this.changeDetectorRef.detectChanges();
            });
        });
    }

    private onMouseDown(event: MouseEvent | Touch) {
        SOM.clearSubscriptions(this.subscription.longPressDown);
        if (this.contextMenuOpened) {
            return;
        }
        if (this.osdProgressBarEl && this.mouseInsideEl(this.osdProgressBarEl.nativeElement, event)) {
            this.mouseInsideProgressBar = true;
            this.mouseInsideProgressBarDown = true;
            this.seekProgress(event);
        } else {
            if (hal.platform === 'TV.WEBOS') {
                // magic mouse long press
                this.subscription.longPressDown = timer(1000).subscribe(() => {
                    this.openContextMenu();
                    this.preventClickEvent = true;
                });
            }
        }
    }

    private onMouseUp() {
        SOM.clearSubscriptions(this.subscription.mouseDown);
        SOM.clearSubscriptions(this.subscription.longPressDown);
        if (this.contextMenuOpened) {
            return;
        }
        this.mouseInsideProgressBar = false;
        if (this.mouseInsideProgressBarDown) {
            this.mouseInsideProgressBarDown = false;
            this.mouseMoving.pipe(take(1)).subscribe((mouseMove) => {
                const mouseMoveTime = this.tileService.calculateProgressTimeFromPer(this.tile, mouseMove);
                this.ngZone.run(() => {
                    SOM.clearSubscriptions(this.subscription.seekToPosition);
                    this.subscription.seekToPosition = this.mediaPlayerV2Service.seekToPosition(
                        mouseMoveTime,
                        mouseMoveTime > this.mediaPlayerV2Service.getCursorTime() ? 1 : -1).subscribe();

                    this.changeDetectorRef.detectChanges();
                });
            });
        }
    }

    private onMouseMove(event: MouseEvent | Touch, isTouch = false) {
        if (this.contextMenuOpened) {
            return;
        }
        if (!this.osdProgressBarEl) {
            return;
        }
        if (this.mouseInsideEl(this.osdProgressBarEl.nativeElement, event) || this.mouseInsideProgressBarDown) {
            this.mouseInsideProgressBar = true;
            // this.keyboardSeekingPreview = false;
            if (isTouch) {
                this.mouseInsideProgressBarDown = true;
                this.seekProgress(event);
            } else {
                const offsetX = event.clientX - this.osdProgressBarEl.nativeElement.getBoundingClientRect().left;
                const cursorPer = Math.floor((offsetX / this.osdProgressBarEl.nativeElement.offsetWidth) * 10000) / 100;
                if (this.mouseInsideProgressBarDown) {
                    this.seekProgress(event);
                } else {
                    this.mouseMoving.next(cursorPer);
                    if (this.tileService.calculateProgressTimeFromPer(this.tile, Math.max(0, cursorPer)) >= Date.now() - 10000) {
                        this.mouseInsideProgressBar = false;
                    }
                }
            }
        } else {
            if (!isTouch) {
                this.mouseInsideProgressBar = false;
                this.mouseInsideProgressBarDown = false;
            }
        }
        if (hal.platform === 'WEB') {
            if (this.mouseTopSide(event)) {
                if (!this.contextMenuOpened) {
                    this.openContextMenu();
                }
            }
        }
    }

    private mouseInsideEl(element: any, event: MouseEvent | Touch) {
        const boundingClientRect = element.getBoundingClientRect();
        const minX = boundingClientRect.left;
        const maxX = minX + element.offsetWidth;
        const minY = boundingClientRect.top;
        const maxY = minY + element.offsetHeight;
        return event.clientX >= minX && event.clientX <= maxX && event.clientY >= minY && event.clientY <= maxY;
    }

    private mouseTopSide(event: MouseEvent | Touch) {
        const boundingClientRect = this.elementRef.nativeElement.getBoundingClientRect();
        const maxY = (boundingClientRect.top + this.elementRef.nativeElement.offsetHeight) / 10;
        return event.clientY <= maxY;
    }

    private isTouchEvent(event: Event) {
        return !!(event as TouchEvent).touches;
    }

    private seekProgress(event: MouseEvent | Touch) {
        const offsetX = event.clientX - this.osdProgressBarEl.nativeElement.getBoundingClientRect().left;
        const cursorPer = Math.floor((offsetX / this.osdProgressBarEl.nativeElement.offsetWidth) * 10000) / 100;
        if (this.tileService.calculateProgressTimeFromPer(this.tile, Math.max(0, cursorPer)) <= Date.now()) {
            const overlap = this.tileService.getOverlap(this.tile);
            if (cursorPer < 0) {
                this.mouseInsideProgressBarDown = false;
                this.mouseInsideProgressBar = false;
                const startTime =
                    this.tileService.isMediaType(this.tile, MediaTypes.NPVR, MediaTypes.EPISODE) ?
                        this.tile.raw.start - overlap.before : this.tile.raw.start - 1;
                this.ngZone.run(() => {
                    SOM.clearSubscriptions(this.subscription.seekToPosition);
                    this.subscription.seekToPosition = this.mediaPlayerV2Service.seekToPosition(startTime, -1).subscribe();
                });
                this.preventClickEvent = true;
                return;
            } else if (cursorPer > 100) {
                this.mouseInsideProgressBarDown = false;
                this.mouseInsideProgressBar = false;
                const startTime =
                    this.tileService.isMediaType(this.tile, MediaTypes.NPVR, MediaTypes.EPISODE)
                        ? this.tile.raw.end + overlap.after : this.tile.raw.end + 1;
                this.ngZone.run(() => {
                    SOM.clearSubscriptions(this.subscription.seekToPosition);
                    this.subscription.seekToPosition = this.mediaPlayerV2Service.seekToPosition(startTime, 1).subscribe();
                });
                this.preventClickEvent = true;
                return;
            }
            this.mouseMoving.next(Math.max(0, cursorPer));
        } else {
            this.mouseInsideProgressBar = false;
            if (this.mouseInsideProgressBarDown) {
                this.mouseInsideProgressBarDown = false;
                this.ngZone.run(() => {
                    SOM.clearSubscriptions(this.subscription.seekToPosition);
                    this.subscription.seekToPosition = this.mediaPlayerV2Service.seekToPosition(Date.now(), 1).subscribe();
                });
            }
        }
    }

    public onMouseButtonsEnter(buttonsPanelComponent: ButtonsPanelComponent) {
        const mouseEnteredIndex = this.buttonsPanelMap.findIndex(
            buttonsPanelComponentItem => buttonsPanelComponentItem === buttonsPanelComponent);
        this.activateButtonsPanel(mouseEnteredIndex);
    }

    private watchResize() {
        this.subscription.onResize = fromEvent(window, 'resize').pipe(
            debounceTime(200),
            distinctUntilChanged()
        ).subscribe(() => {
            this.PROGRESS_BAR_WIDTH = UiHelper.getColumnsVwWidth(10, false);
            this.PROGRESS_BAR_OFFSET_CENTER = UiHelper.getColumnsVwWidth(3) / 2;
            this.changeDetectorRef.detectChanges();
        });
    }

    private watchFullscreen() {
        this.mediaPlayerV2Service.onToggleFullscreen$.subscribe((isFullscreen) => {
            this.isFullscreen = isFullscreen;
            this.changeDetectorRef.detectChanges();
        });
    }

    public onToggleFullscreen() {
        this.mediaPlayerV2Service.toggleFullscreen();
    }

    public onRequestChromecast() {
        this.mediaPlayerV2Service.requestChromecast();
    }

    public onRequestAirplay() {
        this.mediaPlayerV2Service.requestAirplay();
    }

    public actionsPanelIconsOnly() {
        return this.coreService.isMobilePlatform() && UiHelper.resolutionWeb === 'xs';
    }

    private watchGestures() {
        let xStart, yStart;
        this.subscription.touchStart = fromEvent(document, 'touchstart').subscribe((evt: TouchEvent) => {
            const firstTouch = evt.touches[ 0 ];
            xStart = firstTouch.clientX;
            yStart = firstTouch.clientY;
        });
        this.subscription.touchMove = fromEvent(document, 'touchmove').subscribe((evt: TouchEvent) => {
            if (xStart === undefined || yStart === undefined) {
                return;
            }
            if (this.mouseInsideProgressBarDown) {
                return;
            }
            const xMove = evt.touches[ 0 ].clientX;
            const yMove = evt.touches[ 0 ].clientY;

            xStart = xStart || 0;
            yStart = yStart || 0;

            const xDiff = xStart - xMove;
            const yDiff = yStart - yMove;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
                if (xDiff > 0) {
                    /* left swipe */
                    // if (xStart > UiHelper.windowWidth / 2 && Math.abs(xDiff) > 50) {
                    //     this.mediaPlayerV2Service.nextEntity().subscribe();
                    //     xStart = undefined;
                    //     yStart = undefined;
                    // }
                } else {
                    /* right swipe */
                    // if (xStart < UiHelper.windowWidth / 2 && Math.abs(xDiff) > 50) {
                    //     if (this.mediaPlayerV2Service.getWatchingTime() > this.tile.raw.start + 5000) {
                    //         this.mediaPlayerV2Service.startOver().subscribe(() => {
                    //             if (!(this.changeDetectorRef as ViewRef).destroyed) {
                    //                 this.initActionButtons();
                    //             }
                    //         });
                    //         xStart = undefined;
                    //         yStart = undefined;
                    //     } else {
                    //         this.mediaPlayerV2Service.prevEntity().subscribe();
                    //         xStart = undefined;
                    //         yStart = undefined;
                    //     }
                    // }
                }
            } else {
                if (yDiff > 0) {
                    /* up swipe */
                    if (Math.abs(yDiff) > 50) {
                        if (this.contextMenuOpened) {
                            this.closeContextMenu();
                        }
                    }
                } else {
                    /* down swipe */
                    if (yStart < UiHelper.windowHeight / 2 && Math.abs(yDiff) > 50) {
                        if (!this.contextMenuOpened) {
                            this.openContextMenu();
                        }
                    }
                }
            }
        });
        this.subscription.touchEnd = fromEvent(document, 'touchend').subscribe((evt: TouchEvent) => {
            xStart = undefined;
            yStart = undefined;
        });
    }

    private watchBroadcastGaps() {
        this.subscription.onBroadcastGapUpdated = this.mediaPlayerV2Service.onBroadcastGapUpdated$.subscribe(() => {
            this.broadcastGapGroups = this.mediaPlayerV2Service.getChannelBroadcastGapsIntervals();
        });
        this.subscription.onBroadcastGapChanged = this.mediaPlayerV2Service.onBroadcastGapChanged$.subscribe(() => {
            // reset all
            this.mediaPlayerV2Service.lastBroadcastGapClosed = undefined;
            this.closeBroadcastGap(false);
            this.initActiveBroadcastGap();
        });
    }

    private initActiveBroadcastGap() {
        const activeBroadcastGapGroup = this.mediaPlayerV2Service.getActiveBroadcastGapGroup();
        if (activeBroadcastGapGroup) {
            if (activeBroadcastGapGroup?.skippable) {
                if (this.isSeeking()) {
                    return;
                }
                if (activeBroadcastGapGroup.id !== this.mediaPlayerV2Service.lastBroadcastGapClosed) {
                    this.activeBroadcastGapGroup = activeBroadcastGapGroup;
                    this.controllerService.registerActionKey(CommonKeys.OK, this.broadcastGapIdent, () => {
                        this.onBroadcastGapSkipAction();
                        this.closeBroadcastGap();
                    });
                    this.controllerService.registerActionKey(CommonKeys.GRP_BACK, this.broadcastGapIdent, () => {
                        this.closeBroadcastGap();
                    });
                }
            } else {
                this.activeBroadcastGapGroup = activeBroadcastGapGroup;
            }
        }
    }

    public startSeek(dir: number) {
        this.clearIntervalSeek();
        this.ngZone.runOutsideAngular(() => {
            this.seekInterval = setInterval(() => {
                this.seek.emit(dir);
            }, 250);
        });
    }

    public endSeek() {
        this.clearIntervalSeek();
    }

    private clearIntervalSeek() {
        if (this.seekInterval) {
            clearInterval(this.seekInterval);
            this.seekInterval = undefined;
        }
    }

    ngOnDestroy() {
        this.unregisterControls();
        SOM.clearSubscriptionsObject(this.subscription);
    }

    public closeBroadcastGap(userAction = true) {
        SOM.clearSubscriptions(this.subscription.broadcastGapActionTimeout);
        this.controllerService.unregisterStackLevel(this.broadcastGapIdent);
        if (this.activeBroadcastGapGroup) {
            if (userAction) {
                this.mediaPlayerV2Service.lastBroadcastGapClosed = this.activeBroadcastGapGroup.id;
            }
        }
        this.activeBroadcastGapGroup = undefined;
        this.changeDetectorRef.detectChanges();
    }

    public onBroadcastGapSkipAction() {
        this.mediaPlayerV2Service.broadcastGapGroupSkipAction(this.activeBroadcastGapGroup).subscribe();
    }

    public onBroadcastGapSkipComplete() {
        if (this.settings.broadcastGapSkipAuto) {
            this.onBroadcastGapSkipAction();
        }
        this.closeBroadcastGap();
    }
}

