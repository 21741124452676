import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { timer } from 'rxjs';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';

@Component({
    selector: 'app-auto-scroll-container',
    templateUrl: './auto-scroll-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoScrollContainerComponent implements OnInit, OnChanges, OnDestroy {
    @Input() type = 'horizontal';
    @Input() startDelay: number;
    @Input() restartDelay: number;
    @Input() scrollOffset: number = 0;
    @Input() active: boolean = true;

    @Output() scroll: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('autoScrollBox') autoScrollBox: ElementRef;

    private readonly modifier = 2;
    private readonly delta = 1;
    private readonly _speed = {
        horizontal: 75 / this.modifier,
        vertical: 150 / this.modifier
    };
    private readonly _startDelay = {
        horizontal: 2000,
        vertical: 10000
    };

    private readonly _restartDelay = {
        ...this._startDelay,
        horizontal: 5000,
    };

    private offset: number;
    private wrapperSize: number;
    private scrollingElementSize: number;
    private scrolling: boolean = false;

    private subscription: SubscriptionObject = {};

    constructor(
        private changeDetectionRef: ChangeDetectorRef,
        private ngZone: NgZone,
        private elementRef: ElementRef,
        private renderer: Renderer2) {
    }

    ngOnInit() {
        if (this.type) {
            this.renderer.addClass(this.elementRef.nativeElement, 'auto-scroll-' + this.type);
        }
        if (this.active) {
            this.start();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('active')) {
            if (this.active) {
                this.start();
            } else {
                this.stop();
            }
        }
    }

    public start() {
        this.ngZone.runOutsideAngular(() => {
            this.startDelay = this.startDelay !== undefined ? this.startDelay : this._startDelay[ this.type ];
            this.restartDelay = this.restartDelay !== undefined ? this.restartDelay : this._restartDelay[ this.type ];
            // stop if already run
            this.offset = 0;
            this.stop();
            let restartWaitFromTime = null;
            this.subscription.autoScrollInterval = timer(this.startDelay, this.speed).subscribe(() => {
                if (!this.scrolling) {
                    this.scrolling = true;
                    this.renderer.addClass(this.elementRef.nativeElement, 'auto-scroll-is-scrolling');
                    this.changeDetectionRef.detectChanges();
                    this.getSizes();
                    if (!this.wrapperSize || this.scrollingElementSize <= this.wrapperSize) {
                        SOM.clearSubscriptions(this.subscription.autoScrollInterval);
                        return;
                    }
                }
                if (this.scrollingElementSize + this.offset > this.wrapperSize) {
                    this.offset -= this.delta;
                } else {
                    if (restartWaitFromTime === null) {
                        restartWaitFromTime = Date.now();
                    }
                    if (Date.now() - restartWaitFromTime > this.restartDelay) {
                        this.reset();
                    }
                }
                this.scroll.next();
                this.changeDetectionRef.detectChanges();
            });
        });
    }

    public stop() {
        SOM.clearSubscriptions(this.subscription.autoScrollInterval);
        this.offset = 0;
        this.scrolling = false;
        this.renderer.removeClass(this.elementRef.nativeElement, 'auto-scroll-is-scrolling');
    }

    public reset() {
        this.start();
        this.changeDetectionRef.detectChanges();
    }

    public getAutoScrollStyles() {
        const styles = {};
        if (this.isHorizontal()) {
            styles[ 'left' ] = this.offset + 'px';
        } else {
            styles[ 'top' ] = this.offset + 'px';
        }
        return styles;
    }

    public isHorizontal() {
        return this.type === 'horizontal';
    }

    private get speed() {
        return this._speed[ this.type ];
    }

    private getSizes() {
        const dimensionAttribute = this.isHorizontal() ? 'clientWidth' : 'clientHeight';
        this.wrapperSize = (this.elementRef.nativeElement as HTMLElement)[ dimensionAttribute ];
        this.scrollingElementSize = (this.autoScrollBox.nativeElement as HTMLElement)[ dimensionAttribute ] + this.scrollOffset;
    }

    ngOnDestroy() {
        this.stop();
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
