import { Injectable } from '@angular/core';
import { Notification, NotificationShowOptions, NotificationTypes } from '@kuki/global/shared/types/notification';
import { SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { Observable, Subject, Subscription, timer } from 'rxjs';

@Injectable()
export class NotificationService {

    private notificationsChanged: Subject<void> = new Subject<void>();
    public notificationsChanged$: Observable<void> = this.notificationsChanged.asObservable();

    private notificationClosed: Subject<Notification> = new Subject<Notification>();
    public notificationClosed$: Observable<Notification> = this.notificationClosed.asObservable();

    private readonly MAX_STUCK_SIZE: number = 5;
    private readonly DEFAULT_SHOW_OPTIONS: NotificationShowOptions = {
        type: NotificationTypes.ALERT,
        duration: 8000
    };
    private notifications: Array<Notification> = [];
    private notificationsAI: number = 1;

    private subscription: SubscriptionObject = {};

    constructor() {
    }

    public show(label: string, options?: NotificationShowOptions): Notification {
        options = { ...this.DEFAULT_SHOW_OPTIONS, ...options };
        if (options.clear) {
            this.notifications = [];
        }
        const notification = this.createNotification(label, options);
        const existingNotification = this.exists(notification);
        if (existingNotification) {
            this.close(existingNotification);
        }
        this.notifications = [
            notification,
            ...this.notifications
        ];
        this.notifications = this.notifications.slice(0, this.MAX_STUCK_SIZE);
        if (options.duration > 0) {
            this.startCloseTimer(notification, options.duration);
        }
        this.notificationsChanged.next();
        return notification;
    }

    public clear() {
        // don't clear persistent
        this.notifications = this.notifications.filter(notification => notification.persistent);
        this.notificationsChanged.next();
    }

    public close(notification: Notification) {
        if (!notification) {
            return;
        }
        this.notifications = this.notifications.filter(notificationItem => notificationItem.id !== notification.id);
        this.notificationsChanged.next();
        this.notificationClosed.next(notification);
    }

    public closeByGuid(guid: string) {
        if (!guid) {
            return;
        }
        this.notifications = this.notifications.filter(notificationItem => notificationItem.guid !== guid);
        this.notificationsChanged.next();
    }

    public someNotifications() {
        return this.notifications && this.notifications.length > 0;
    }

    public getNotifications() {
        return this.notifications;
    }

    private createNotification(label: string, options?: NotificationShowOptions): Notification {
        return {
            id: this.notificationsAI++,
            label: label,
            guid: options.guid,
            text: options.text,
            type: options.type,
            persistent: options.persistent,
            messageId: options.messageId,
            cssClass: options.cssClass,
            iconCssClass: options.iconCssClass,
            buttons: options.buttons
        };
    }

    private startCloseTimer(notification: Notification, duration: number) {
        this.subscription.closeTimers = this.subscription.closeTimers as Array<Subscription> || [];
        const subscription = timer(duration).subscribe(() => {
            this.close(notification);
        });
        this.subscription.closeTimers.push(subscription);
    }

    private exists(notification: Notification) {
        return this.notifications.find(notificationItem =>
            (notification.guid && notificationItem.guid === notification.guid) ||
            (
                notificationItem.type === notification.type &&
                notificationItem.label === notification.label &&
                notificationItem.text === notification.text
            )
        );
    }
}
