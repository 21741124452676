import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '@kuki/environments/environment';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { CacheControlService } from '@kuki/global/shared/services/cache-control.service';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { TagService } from '@kuki/global/shared/services/tag.service';
import { WatchedService } from '@kuki/global/shared/services/watched.service';
import { Profile, ProfileIcon, ProfileType } from '@kuki/global/shared/types/profile';
import { Settings } from '@kuki/global/shared/types/settings';
import { ProfileTile } from '@kuki/global/shared/types/tile';
import { EpgService } from '@kuki/tv/features/epg/epg.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, from, merge, Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { hal } from '@kuki/platforms/hal';
import { ChannelService } from './channel.service';

@Injectable()
export class ProfileService {

    private activeProfile: ProfileTile;
    private profiles: Array<ProfileTile>;
    private profileTypes: Array<ProfileType>;
    private storedPassword: string;

    private onProfilesFetched: Subject<void> = new Subject<void>();
    public onProfilesFetched$: Observable<void> = this.onProfilesFetched.asObservable();

    private onProfileActivated: Subject<void> = new Subject<void>();
    public onProfileActivated$: Observable<void> = this.onProfileActivated.asObservable();

    private onProfileDeactivated: Subject<void> = new Subject<void>();
    public onProfileDeactivated$: Observable<void> = this.onProfileDeactivated.asObservable();

    private onProfileTypeChanged: Subject<void> = new Subject<void>();
    public onProfileTypeChanged$: Observable<void> = this.onProfileTypeChanged.asObservable();

    private onProfileIconChanged: Subject<void> = new Subject<void>();
    public onProfileIconChanged$: Observable<void> = this.onProfileIconChanged.asObservable();

    private onProfileSettingsChanged: Subject<Array<string>> = new Subject<Array<string>>();
    public onProfileSettingsChanged$: Observable<Array<string>> = this.onProfileSettingsChanged.asObservable();

    public onProfileChanged$ = merge(this.onProfileActivated$, this.onProfileDeactivated$);

    constructor(
        private cacheControlService: CacheControlService,
        private generalService: GeneralService,
        private watchedService: WatchedService,
        private tagService: TagService,
        private httpClient: HttpClient,
        private navigationService: NavigationService,
        private settingsService: SettingsService,
        private channelService: ChannelService,
        private translateService: TranslateService,
        @Optional() private epgService: EpgService,
        @Inject('ModalsService') private modalsService: ModalsInterface) {
    }

    public initProfile() {
        return this.getActiveProfileFromApi()
            .pipe(switchMap((activeProfile) => {
                if (activeProfile) {
                    return this.setupActiveProfile(activeProfile, false);
                } else {
                    if (this.profiles?.length) {
                        if (this.profiles.length === 1 && !this.profiles[ 0 ].pin) {
                            return this.activateProfileProcess(this.profiles[ 0 ].id, false);
                        } else {
                            // TODO: find better solution, cant return because device not ready and navigation is waiting
                            this.navigationService.navigateSelectProfile();
                            return of(null);
                        }
                    }
                }
                return of(null);
            }));
    }

    public initProfileFromWizard() {
        this.navigationService.preventStoreNavState = true;
        if (this.profiles?.length) {
            if (this.profiles.length === 1 && !this.profiles[ 0 ].pin) {
                return this.activateProfileProcess(this.profiles[ 0 ].id)
                    .pipe(switchMap(() => this.navigationService.navigateHome()));
            } else {
                return from(this.navigationService.navigateSelectProfile()).pipe(tap(() => this.navigationService.clearNavHistory()));
            }
        }
        return from(this.navigationService.navigateCreateProfile()).pipe(tap(() => this.navigationService.clearNavHistory()));
    }

    public destroy() {
        this.activeProfile = undefined;
        this.profiles = [];
        this.profileTypes = [];
        this.clearLastActiveProfile();
    }

    public fetchProfiles() {
        return this.httpClient.get<Array<ProfileTile>>(environment.apiUrl + 'profile/list').pipe(switchMap((profiles) => {
            this.profiles = profiles;
            // check and update active profile
            if (this.activeProfile) {
                const tempProfile = { ...this.activeProfile };
                this.activeProfile = profiles.find(profile => this.activeProfile.id === profile.id);
                if (!this.activeProfile) {
                    // force deactivate, profile doesnt exists
                    return this.deactivateProfileSetup();
                }
                if (this.activeProfile.profileType !== tempProfile.profileType) {
                    this.onProfileTypeChanged.next();
                }
                if (this.activeProfile.iconId !== tempProfile.iconId) {
                    this.onProfileIconChanged.next();
                }
            }
            this.onProfilesFetched.next();
            return of(undefined);
        }));
    }

    public getProfiles(onlyChilds: boolean = false): Array<ProfileTile> {
        if (onlyChilds) {
            return this.profiles.filter(profile => profile.profileType === 'kid');
        }
        return this.profiles;
    }


    public getProfileTypes(): Array<ProfileType> {
        return this.profileTypes;
    }

    public getProfileIcons() {
        return this.httpClient.get<Array<ProfileIcon>>(environment.apiUrl + 'profile/icon-list');
    }

    public getActiveProfile() {
        return this.activeProfile;
    }

    public getProfile(profileId: number): ProfileTile {
        return this.profiles.find(profile => profile.id === profileId);
    }

    public createProfileProcess(profile: Profile) {
        return this.deactivateProfileProcess().pipe(
            switchMap(() => this.createProfile(profile)),
            switchMap((profileTile) => this.activateProfile(profileTile.id))
        );
    }

    public activateProfileProcess(profileId: number, clearCache: boolean = true) {
        const profileTile = this.getProfile(profileId);
        if (!profileTile) {
            return of(null);
        }
        if (profileTile.pin) {
            return from(this.checkProfilePinWithModal(profileTile.id)).pipe(switchMap((pin) => {
                return this.activateProfile(profileTile.id, pin, clearCache);
            }));
        } else {
            return this.activateProfile(profileTile.id, undefined, clearCache);
        }
    }

    public deactivateProfileProcess(deactivateKid: boolean = true) {
        if (!this.activeProfile) {
            return of(null);
        }
        if (this.activeProfile.profileType === 'kid') {
            if (deactivateKid) {
                return from(this.checkAccountPasswordWithModal())
                    .pipe(
                        switchMap((password) => this.deactivateProfile(password))
                    );
            } else {
                return of(null);
            }
        } else {
            return this.deactivateProfile();
        }
    }

    public deleteProfileProcesss(profileId: number) {
        const profileTile = this.getProfile(profileId);
        if (!profileTile) {
            return of(null);
        }
        if (profileTile.pin || (this.activeProfile && this.activeProfile.profileType === 'kid')) {
            return from(this.checkProfilePasswordWithModal(profileTile.id)).pipe(switchMap((password) => {
                return this.deleteProfile(profileTile.id, password);
            }), switchMap(() => {
                return of(null);
            }));
        } else {
            return from(this.modalsService.openConfirmModal()).pipe(switchMap(() => this.deleteProfile(profileTile.id)));
        }
    }

    private setupActiveProfile(activeProfile: ProfileTile, clearCache: boolean = false) {
        return this.settingsService.fetchProfileSettings().pipe(
            tap(() => {
                this.activeProfile = activeProfile;
                if (clearCache) {
                    this.cacheControlService.clear();
                    this.watchedService.clear();
                    this.tagService.clear();
                    this.navigationService.clearNavHistory();
                    if (this.epgService) {
                        this.epgService.clear();
                    }
                }
            }),
            switchMap(() => {
                if (this.activeProfile.locale) {
                    return this.translateService.use(this.activeProfile.locale)
                        .pipe(
                            switchMap(() => forkJoin([
                                this.settingsService.fetchSettingsSchema(),
                                this.generalService.fetchLanguages(),
                                this.channelService.fetchChannelList(),
                                this.fetchProfileTypes()
                            ])),
                        );
                }
                return of(null);
            }),
            tap(() => {
                this.onProfileActivated.next();
                this.storeLastActiveProfile();
            }));
    }

    public checkProfilePin(profileId: number, pin?: string) {
        return this.httpClient.post<any>(environment.apiUrl + 'profile/check-pin', {
            profileId: profileId,
            pin: pin
        });
    }

    public checkPassword(profileId: number, password: string) {
        return this.httpClient.post<any>(environment.apiUrl + 'profile/check-password', {
            profileId: profileId,
            password: password
        });
    }

    public activateProfile(profileId: number, pin?: string, clearCache: boolean = true) {
        const profileTile = this.getProfile(profileId);
        if (!profileTile) {
            return of(null);
        }
        return this.httpClient.post<any>(environment.apiUrl + 'profile/activate', {
            profileId: profileTile.id,
            pin: pin
        }).pipe(
            switchMap(() => this.setupActiveProfile(profileTile, clearCache))
        );
    }

    public deactivateProfile(password?: string) {
        return this.httpClient.post<any>(environment.apiUrl + 'profile/deactivate', {
            password: password
        }).pipe(
            switchMap(() => this.deactivateProfileSetup())
        );
    }

    public getProfileType(profileId: number) {
        const profileTile = this.getProfile(profileId);
        if (!profileTile) {
            return;
        }
        return this.profileTypes.find(profileType => profileType.value === profileTile.profileType);
    }

    private createProfile(profile: Profile) {
        return this.httpClient.post<ProfileTile>(environment.apiUrl + 'profile', profile).pipe(
            tap(() => this.activeProfile = null),
            switchMap((profileTile) => this.fetchProfiles().pipe(map(() => profileTile)))
        );
    }

    public updateProfile(id: number, profile: Profile) {
        return this.httpClient.post<ProfileTile>(environment.apiUrl + 'profile/' + id, profile).pipe(switchMap((profileTile) => {
            return this.fetchProfiles().pipe(map(() => profileTile));
        }));
    }

    public getActiveProfileFromApi() {
        return this.httpClient.get<ProfileTile>(environment.apiUrl + 'profile/active');
    }

    private deleteProfile(id: number, password?: string) {
        return this.httpClient.post<any>(environment.apiUrl + 'profile/delete/' + id, {
            password: password
        }).pipe(
            switchMap(() => this.fetchProfiles())
        );
    }

    public fetchProfileTypes(): Observable<Array<ProfileType>> {
        return this.httpClient.get<Array<ProfileType>>(environment.apiUrl + 'profile/profile-types')
            .pipe(
                tap((profileTypes) => {
                        this.profileTypes = profileTypes;
                    }
                )
            );
    }

    public profileTypeChanged() {
        this.onProfileTypeChanged.next();
    }

    public checkAccountPasswordWithModal(data?: any) {
        return this.modalsService.openPasswordModal(data);
    }

    private checkProfilePasswordWithModal(profileId: number) {
        return this.modalsService.openPasswordModal({ type: 'profile', profileId: profileId });
    }

    private checkProfilePinWithModal(profileId: number) {
        return this.modalsService.openPinModal({
            type: 'profile',
            profileId: profileId,
            message: this.translateService.instant('MODAL.PIN_PROFILE.MESSAGE')
        });
    }

    private deactivateProfileSetup() {
        this.activeProfile = null;
        this.cacheControlService.clear();
        this.watchedService.clear();
        this.tagService.clear();
        this.settingsService.clearProfileSettings();
        this.navigationService.clearNavHistory();
        if (this.epgService) {
            this.epgService.clear();
        }
        this.onProfileDeactivated.next();
        return this.translateService.use(this.settingsService.getParsedSettingsValue<string>('nbx.stblang'))
            .pipe(
                switchMap(() => forkJoin([
                    this.settingsService.fetchSettingsSchema(),
                    this.generalService.fetchLanguages(),
                    this.fetchProfileTypes()
                ])),
            );
    }

    public getSettings(profileId?: number) {
        // TODO: SIMPLIFY
        const profile = profileId ? this.getProfile(profileId) : this.activeProfile;
        if (profile) {
            // get someone else
            if (profileId && (!this.activeProfile || (this.activeProfile.id !== profileId))) {
                return this.settingsService.getTempProfileSettings(profileId);
            } else { // get me
                return of(this.settingsService.getSettings());
            }
        }
        return of(this.settingsService.getSettings());
    }

    public getParsedSettings(profileId?: number) {
        // TODO: SIMPLIFY
        const profile = profileId ? this.getProfile(profileId) : this.activeProfile;
        if (profile) {
            // get someone else
            if (profileId && (!this.activeProfile || (this.activeProfile.id !== profileId))) {
                return this.settingsService.getTempProfileParsedSettings(profileId);
            } else { // get me
                return of(this.settingsService.getParsedSettings());
            }
        }
        return of(this.settingsService.getParsedSettings());
    }

    public getSettingsValue(key: string, profileId?: number): Observable<string> {
        // TODO: SIMPLIFY
        const profile = profileId ? this.getProfile(profileId) : this.activeProfile;
        if (profile) {
            // get someone else
            if (profileId && (!this.activeProfile || (this.activeProfile.id !== profileId))) {
                return this.settingsService.getTempProfileSettingsValue(profileId, key);
            } else { // get me
                return of(this.settingsService.getSettingsValue(key));
            }
        }
        return of(this.settingsService.getSettingsValue(key));
    }

    public getParsedSettingsValue<T = string>(key: string, profileId?: number): Observable<T> {
        // TODO: SIMPLIFY
        const profile = profileId ? this.getProfile(profileId) : this.activeProfile;
        if (profile) {
            // get someone else
            if (profileId && (!this.activeProfile || (this.activeProfile.id !== profileId))) {
                return this.settingsService.getTempProfileParsedSettingsValue<T>(profileId, key);
            } else { // get me
                return of(this.settingsService.getParsedSettingsValue<T>(key));
            }
        }
        return of(this.settingsService.getParsedSettingsValue<T>(key));
    }

    public storeSettings(settings: Partial<Settings>, profileId?: number) {
        // TODO: SIMPLIFY
        const profile = profileId ? this.getProfile(profileId) : this.activeProfile;
        if (profile) {
            // editing someone else
            if (profileId && (!this.activeProfile || (this.activeProfile.id !== profileId))) {
                return this.settingsService.storeTemporaryProfileSettings(profileId, settings);
            } else { // editing me
                return this.settingsService.storeProfileSettings(settings);
            }
        } else {
            return this.settingsService.storeSettings(settings);
        }
    }

    public storeSettingsKey(key: string, value: string, profileId?: number) {
        // TODO: SIMPLIFY
        const profile = profileId ? this.getProfile(profileId) : this.activeProfile;
        if (profile) {
            // editing someone else
            if (profileId && (!this.activeProfile || (this.activeProfile.id !== profileId))) {
                return this.settingsService.storeTemporaryProfileSettingsKey(profileId, key, value);
            } else { // editing me
                return this.settingsService.storeProfileSettingsKey(profile.id, key, value);
            }
        } else {
            return this.settingsService.storeSettingsKey(key, value);
        }
    }

    public getLang(profileId?: number) {
        const profile = profileId ? this.getProfile(profileId) : this.activeProfile;
        if (profile && profile.locale) {
            return profile.locale;
        } else {
            return this.settingsService.getSettingsValue('nbx.stblang');
        }
    }

    public storeLangSettings(profileId: number, lang: string, password?: string) {
        // TODO: SIMPLIFY
        const profile = profileId ? this.getProfile(profileId) : this.activeProfile;
        if (profile) {
            return this.updateProfile(profile.id, {
                locale: lang,
                password: password
            });
        } else {
            return this.settingsService.storeSettingsKey('nbx.stblang', lang);
        }
    }

    public refreshProfileSettings(profileId: number) {
        if (!this.activeProfile || profileId !== this.activeProfile.id) {
            return of(undefined);
        }
        let tempProfileSettings;
        return (this.getSettings() as Observable<any>).pipe(
            tap((settings) => {
                tempProfileSettings = settings;
            }),
            switchMap(() => this.settingsService.fetchProfileSettings(true)),
            switchMap(() => this.getSettings()),
            tap((profileSettings) => {
                const delta = [];
                Object.keys(profileSettings).forEach((key) => {
                    if (profileSettings[ key ] !== tempProfileSettings[ key ]) {
                        delta.push(key);
                    }
                });
                this.onProfileSettingsChanged.next(delta);
            })
        );
    }

    public storePassword(password) {
        this.storedPassword = password;
    }

    public getStoredPassword() {
        return this.storedPassword;
    }

    public clearStoredPassword() {
        this.storedPassword = undefined;
    }

    public profileIconChanged() {
        this.onProfileIconChanged.next();
    }

    private storeLastActiveProfile() {
        if (hal.platform === 'TV.ARRIS' ) {
            return;
        }
        if (this.activeProfile) {
            localStorage?.setItem('lastActiveProfile', this.activeProfile.guid);
        }
    }

    public getLastActiveProfile() {
        if (hal.platform === 'TV.ARRIS' ) {
            return;
        }
        return localStorage?.getItem('lastActiveProfile');
    }

    public clearLastActiveProfile() {
        if (hal.platform === 'TV.ARRIS' ) {
            return;
        }
        return localStorage?.removeItem('lastActiveProfile');
    }
}
