import { Injectable } from '@angular/core';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { BehaviorSubject, fromEvent, merge, Observable, Subject, timer } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';

@Injectable()
export class ScreenSaverService {

    private readonly SCREEN_SAVER_TIMEOUT = 3 * 60 * 1000; // 3 minutes
    private onRefreshScreenSaverTimeout: Subject<void> = new Subject<void>();

    private onScreenSaverShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public onScreenSaverShow$: Observable<boolean> = this.onScreenSaverShow.asObservable();

    private subscription: SubscriptionObject = {};

    constructor(
        private settingsService: SettingsService,
        private controllerService: ControllerService) {
    }

    public init() {
        SOM.clearSubscriptionsObject(this.subscription);
        if (!this.settingsService.getParsedSettingsValue<boolean>('screenSaver')) {
            return;
        }
        this.subscription.showScreenSaver = this.onRefreshScreenSaverTimeout
            .pipe(
                startWith(true),
                switchMap(() => timer(this.SCREEN_SAVER_TIMEOUT)),
            )
            .subscribe(() => {
                this.onScreenSaverShow.next(true);
            });

        // detect user actions to refresh timeout
        this.subscription.watchUserActions = merge(
            this.controllerService.keyUpEvent$,
            fromEvent(document, 'mouseup')
        ).subscribe((eventData) => {
            if (this.onScreenSaverShow.getValue()) {
                // if visible, hide
                this.destroy();
            } else {
                // if not visible, refresh timeout
                this.onRefreshScreenSaverTimeout.next();
            }
        });
    }

    public isVisible() {
        return this.onScreenSaverShow.getValue();
    }

    public destroy(init: boolean = true) {
        this.onScreenSaverShow.next(false);
        SOM.clearSubscriptionsObject(this.subscription);
        if (init) {
            this.init();
        }
    }
}
