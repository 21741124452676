import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    defaultProfileSettings,
    defaultSettings,
    getDefaultArrisSettings,
    profileObjectKeys,
    settingsProfileObjectKeys
} from '@kuki/data/settings/settings.config';
import { environment } from '@kuki/environments/environment';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { DeviceTypes } from '@kuki/global/shared/types/device';
import { Actions, TileTypes } from '@kuki/global/shared/types/enum';
import { PlatformSwitch, SelectOption } from '@kuki/global/shared/types/general';
import { Settings, SettingsItem, SettingsParsed, SettingsSection } from '@kuki/global/shared/types/settings';
import { ProfileTile, Tile } from '@kuki/global/shared/types/tile';
import { hal } from '@kuki/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { TranslateService } from '@ngx-translate/core';
import { ConnectableObservable, Observable, of, Subject, timer } from 'rxjs';
import { map, share, switchMap, tap } from 'rxjs/operators';
import { PortalSettingsService } from './portal-settings.service';

declare var toi;

@Injectable()
export class SettingsService {

    private settings: Settings;
    private profileSettings: Settings;
    private parsedSettings: SettingsParsed;
    public activeSettingsLevel: number = 0;
    public passwordAccepted: boolean = false;
    private settingsSchema: Array<SettingsSection>;
    private fetchedSettings: boolean = false;
    private tempProfileSettings: { [ key: number ]: Settings } = {};
    private tempProfileParsedSettings: { [ key: number ]: SettingsParsed } = {};
    private platformSwitch: PlatformSwitch;
    private readonly PLATFORM_SWITCH_REFRESH_INTERVAL = 2 * 60 * 60 * 1000; // 2 hours;
    private subscription: SubscriptionObject = {};
    private getTempProfileSettingsRequest$: { [ key: number ]: ConnectableObservable<any> } = {};

    private settingsUpdated: Subject<void> = new Subject<void>();
    public settingsUpdated$: Observable<void> = this.settingsUpdated.asObservable();
    private settingsSchemaUpdated: Subject<void> = new Subject<void>();
    public settingsSchemaUpdated$: Observable<void> = this.settingsSchemaUpdated.asObservable();
    private settingsLevelUpdated: Subject<number> = new Subject<number>();
    public settingsLevelUpdated$: Observable<number> = this.settingsLevelUpdated.asObservable();

    constructor(
        private notificationService: NotificationService,
        private portalSettingsService: PortalSettingsService,
        private httpClient: HttpClient,
        private translateService: TranslateService,
        @Inject('PlatformHalService') private platformHalService: PlatformHal) {
    }

    public init() {
        let settings = defaultSettings;
        // Arris settings state
        if (hal.platform === 'TV.ARRIS' && hal.deviceType === DeviceTypes.STB) {
            settings = {
                ...settings,
                ...getDefaultArrisSettings(toi)
            };
        }
        // clear
        this.settings = {} as any;
        this.profileSettings = {} as any;
        this.storeSettings(settings, false);
    }

    public initProfileSettings(profileTile: ProfileTile) {
        const globalProfileSettings = {};
        Object.keys(this.settings).forEach(key => {
            if (this.isProfileSettingsKey(key)) {
                globalProfileSettings[ key ] = this.settings[ key ];
            }
        });
        const settings = { ...globalProfileSettings, ...this.profileSettings, ...defaultProfileSettings[ profileTile.profileType ] };
        return this.publishProfileSettings(profileTile.id, settings);
    }

    public loadSettingsFromPlatform() {
        // console.log('loadSettings');
        if (!this.platformHalService.loadSettingsValue) {
            return;
        }
        Object.keys(this.settings).forEach(key => {
            const storedSettingsValue = this.platformHalService.loadSettingsValue(key);
            if (storedSettingsValue !== undefined) {
                this.settings[ key ] = storedSettingsValue;
            }
        });
        this.parsedSettings = this.parseSettings();
        // console.log('Load settings from flash');
        // console.log(JSON.stringify(this.settings));
    }

    public fetchSettings() {
        return this.httpClient.get<Settings>(`${ environment.apiUrl }settings`).pipe(switchMap((settings) => {
            let missingSettings = false;
            this.fetchedSettings = true;
            Object.keys(this.settings).forEach(key => {
                if (settings[ key ] === undefined) {
                    missingSettings = true;
                }
            });
            this.storeSettings(settings, false).subscribe();
            if (missingSettings) {
                return this.publishSettings();
            }
            return of(true);
        }));
    }

    public fetchProfileSettings(refresh: boolean = false) {
        return this.getProfileSettings().pipe(switchMap((settings) => {
            let missingSettings, notUserKey = false;
            if (!refresh) {
                Object.keys(settings).forEach(key => {
                    if (!this.isProfileSettingsKey(key) && this.settings[ key ]) {
                        settings[ key ] = undefined;
                        notUserKey = true;
                    }
                });
                Object.keys(this.profileSettings).forEach(key => {
                    // if key has value on client but no value on server
                    if (this.profileSettings[ key ] !== undefined && settings[ key ] === undefined) {
                        missingSettings = true;
                    }
                });
            }
            this.storeProfileSettings(settings, false).subscribe();
            if (missingSettings || notUserKey) {
                return this.publishProfileSettings();
            }
            return of(true);
        }));
    }

    public getTempProfileSettings(profileId: number) {
        if (this.tempProfileSettings[ profileId ]) {
            return of({ ...this.settings, ...this.tempProfileSettings[ profileId ] });
        }
        this.getTempProfileSettingsRequest$[ profileId ] = this.getTempProfileSettingsRequest$[ profileId ] ||
            this.getProfileSettings(profileId)
                .pipe(map((settings) => {
                    this.tempProfileSettings[ profileId ] = settings;
                    this.tempProfileParsedSettings [ profileId ] =
                        this.parseSettings({ ...this.settings, ...this.tempProfileSettings[ profileId ] });
                    return { ...this.settings, ...this.tempProfileSettings[ profileId ] };
                }), share()) as ConnectableObservable<any>;
        return this.getTempProfileSettingsRequest$[ profileId ];
    }

    public getTempProfileParsedSettings(profileId: number) {
        if (this.tempProfileParsedSettings[ profileId ]) {
            return of(this.tempProfileParsedSettings[ profileId ]);
        }
        return (this.getTempProfileSettings(profileId) as Observable<any>).pipe(map(() => {
            return this.tempProfileParsedSettings[ profileId ];
        }));
    }

    public getTempProfileSettingsValue<T = string>(profileId: number, key: string): Observable<T> {
        return (this.getTempProfileSettings(profileId) as Observable<any>).pipe(map((settings) => {
            return settings[ key ];
        }));
    }

    public getTempProfileParsedSettingsValue<T = string>(profileId: number, key: string): Observable<T> {
        return this.getTempProfileParsedSettings(profileId).pipe(map((settings) => {
            return settings[ key ];
        }));
    }

    public getProfileSettings(profileId?: number) {
        let httpParams = new HttpParams();
        if (profileId) {
            httpParams = httpParams.append('profileId', profileId.toString());
        }
        return this.httpClient.get<Settings>(`${ environment.apiUrl }profile/settings`, { params: httpParams });
    }

    public fetchPlatformSwitch() {
        if (!this.subscription.platformSwitchRefreshInterval) {
            this.startPlaformSwitchRefreshInterval();
        }
        return this.httpClient.get<PlatformSwitch>(`${ environment.apiUrl }platform-switch`).pipe(tap((platformSwitch) => {
            this.platformSwitch = platformSwitch;
        }));
    }

    public initSettingsStoring() {
        if (!this.platformHalService.storeSettingsValue) {
            return;
        }
        this.subscription.settingsUpdated = this.settingsUpdated$.subscribe(() => {
            // console.log('store settings to hal:');
            // console.log(JSON.stringify(settings));
            Object.keys(this.getSettings()).forEach(key => {
                this.platformHalService.storeSettingsValue(key, this.settings[ key ]);
            });
        });
    }

    public getSettings(): Settings {
        return { ...this.settings, ...this.profileSettings };
    }

    public getParsedSettings(): SettingsParsed {
        // dont use { ...parsedSettigns }, due to getParsedSettings() calling on each tilesPanel move
        return this.parsedSettings;
    }

    public getSettingsValue(key): string {
        return this.getSettings()[ key ];
    }

    public getParsedSettingsValue<T = string>(key): T {
        return this.getParsedSettings()[ key ];
    }

    private parseSettings(settings: Settings = this.getSettings()) {
        return {
            ...settings,
            parentalLock: settings.parentalLock === '1',
            unlockTimeout: parseInt(settings.unlockTimeout, 10),
            ageLimit: parseInt(settings.ageLimit, 10),
            bitrate: parseInt(settings.bitrate, 10),
            debug: settings.debug === '1',
            useMobileData: settings.useMobileData === '1',
            hdmiStbOff: settings.hdmiStbOff === '1',
            hdmiTvOff: settings.hdmiTvOff === '1',
            animations: settings.animations === '1',
            quickPlay: settings.quickPlay === '1',
            infoPanel: settings.infoPanel === '1',
            dashboardStartOnChannel: settings.dashboardStartOnChannel === '1',
            continuingPlay: settings.continuingPlay === '1',
            videoPreview: settings.videoPreview === '1',
            screenSaver: settings.screenSaver === '1',
            broadcastGapSkipAuto: settings.broadcastGapSkipAuto === '1',
            lockPlayerLandscape: settings.lockPlayerLandscape === '1',
            backgroundPlay: settings.backgroundPlay === '1'
        };
    }

    public storeSettings(settings: any, publish = true): Observable<any> {
        this.settings = { ...this.settings, ...settings };
        this.parsedSettings = this.parseSettings();
        this.settingsUpdated.next();
        if (publish) {
            return this.publishSettings();
        }
        return of(null);
    }

    public storeSettingsKey(key, value, publish: boolean = true): Observable<any> {
        this.settings[ key ] = value;
        this.parsedSettings = this.parseSettings();
        this.settingsUpdated.next();
        if (publish) {
            return this.publishSettings();
        }
        return of(null);
    }

    public storeProfileSettings(settings: Partial<Settings>, publish = true): Observable<any> {
        this.profileSettings = { ...this.profileSettings, ...settings };
        this.parsedSettings = this.parseSettings();
        this.settingsUpdated.next();
        if (publish) {
            return this.publishProfileSettings();
        }
        return of(null);
    }

    public storeProfileSettingsKey(profileId: number, key: string, value: string, publish: boolean = true): Observable<any> {
        this.profileSettings[ key ] = value;
        this.parsedSettings = this.parseSettings();
        this.settingsUpdated.next();
        if (publish) {
            return this.publishProfileSettings(profileId);
        }
        return of(null);
    }

    public deleteProfileSettingsKey(profileId: number, key: string, publish: boolean = true): Observable<any> {
        delete (this.profileSettings[ key ]);
        this.parsedSettings = this.parseSettings();
        this.settingsUpdated.next();
        if (publish) {
            return this.publishProfileSettings(profileId);
        }
        return of(null);
    }

    public storeTemporaryProfileSettings(profileId: number, settings: Partial<Settings>, publish: boolean = true) {
        this.tempProfileSettings[ profileId ] = this.tempProfileSettings[ profileId ] || {} as any;
        this.tempProfileSettings[ profileId ] = { ...this.tempProfileSettings[ profileId ], ...settings };
        this.tempProfileParsedSettings[ profileId ] = this.parseSettings({ ...this.settings, ...this.tempProfileSettings[ profileId ] });
        this.settingsUpdated.next();
        if (publish) {
            return this.publishProfileSettings(profileId, this.tempProfileSettings[ profileId ]);
        }
        return of(null);
    }

    public storeTemporaryProfileSettingsKey(profileId: number, key: string, value: string, publish: boolean = true) {
        this.tempProfileSettings[ profileId ] = this.tempProfileSettings[ profileId ] || {} as any;
        this.tempProfileSettings[ profileId ][ key ] = value;
        this.tempProfileParsedSettings[ profileId ] = this.parseSettings({ ...this.settings, ...this.tempProfileSettings[ profileId ] });
        this.settingsUpdated.next();
        if (publish) {
            return this.publishProfileSettings(profileId, this.tempProfileSettings[ profileId ]);
        }
        return of(null);
    }

    public deleteTemporaryProfileSettingsKey(profileId: number, key: string, publish: boolean = true) {
        if (!this.tempProfileSettings[ profileId ]) {
            return;
        }
        delete (this.tempProfileSettings[ profileId ][ key ]);
        this.tempProfileParsedSettings[ profileId ] = this.parseSettings({ ...this.settings, ...this.tempProfileSettings[ profileId ] });
        this.settingsUpdated.next();
        if (publish) {
            return this.publishProfileSettings(profileId, this.tempProfileSettings[ profileId ]);
        }
        return of(null);
    }

    public publishSettings() {
        if (!this.fetchedSettings) {
            return of(null);
        }
        return this.httpClient.post(`${ environment.apiUrl }settings`, this.settings);
    }

    public publishProfileSettings(profileId?: number, settings: Settings = this.profileSettings) {
        let httpParams = new HttpParams();
        if (profileId) {
            httpParams = httpParams.append('profileId', profileId.toString());
        }
        return this.httpClient.post(`${ environment.apiUrl }profile/settings`, settings, {
            params: httpParams
        });
    }

    public clearLocalSettings() {
        this.init();
        this.settingsUpdated.next();
    }

    public clearProfileSettings() {
        this.profileSettings = {} as any;
        this.parsedSettings = this.parseSettings();
    }

    public clearTempProfileSettings() {
        this.tempProfileSettings = {};
        this.tempProfileParsedSettings = {};
        this.getTempProfileSettingsRequest$ = {};
    }

    public clearSettings() {
        return this.httpClient.post(`${ environment.apiUrl }settings`, {});
    }

    public fetchSettingsSchema(platform = hal.platform) {
        return this.httpClient.get<Array<SettingsSection>>(`${ environment.apiUrl }section-settings?platform=${ platform }`)
            .pipe(tap((settingsSchema) => {
                this.settingsSchema = settingsSchema;
                const profileSettings = this.settingsSchema.find(section => section.key === 'profile');
                if (hal.platform !== 'WEB' && !profileSettings.items.some(item => item.key === 'broadcast-gap-skip-auto')) {
                    const broadcastGapSkipAuto = {
                        'key': 'broadcast-gap-skip-auto',
                        text: this.translateService.instant('SECTIONS.SETTINGS.GENERAL.BROADCAST_GAP_SKIP_AUTO'),
                        'valueKey': 'broadcastGapSkipAuto'
                    };
                    profileSettings.items.push(broadcastGapSkipAuto);
                }
                const deviceSettings = this.settingsSchema.find(section => section.key === 'device');
                if ([ 'TV.WEBOS', 'TV.TIZEN', 'TV.ANDROID' ].indexOf(hal.platform) >= 0) {
                    if (!deviceSettings.items.some((item => item.key === 'restart'))) {
                        deviceSettings.items.push({
                            'key': 'restart',
                            text: this.translateService.instant('SECTIONS.SETTINGS.GENERAL.RESTART')
                        });
                    }
                    if (!deviceSettings.items.some((item => item.key === 'self-test'))) {
                        deviceSettings.items.push({
                            'key': 'self-test',
                            text: this.translateService.instant('SECTIONS.SETTINGS.GENERAL.SELF_TEST')
                        });
                    }
                }
                const audioSubItem = profileSettings.items.find(item => item.key === 'audio-sub');
                if (audioSubItem) {
                    audioSubItem.fullscreen = true;
                }
                if ([ 'MOBILE.IOS', 'MOBILE.ANDROID' ].indexOf(hal.platform) >= 0) {
                    const index = deviceSettings.items.findIndex(item => item.key === 'upload-logs');
                    const settings = [
                        {
                            key: 'lock-player-landscape',
                            valueKey: 'lockPlayerLandscape',
                            text: this.translateService.instant('SECTIONS.SETTINGS.GENERAL.LOCK_PLAYER_LANDSCAPE')
                        },
                        {
                            key: 'background-play',
                            valueKey: 'backgroundPlay',
                            text: this.translateService.instant('SECTIONS.SETTINGS.GENERAL.BACKGROUND_PLAY')
                        },
                    ];
                    deviceSettings.items.splice(index, 0, ...settings);
                }
                if ([ 'TV.ARRIS' ].indexOf(hal.platform) >= 0) {
                    if (hal.platform === 'TV.ARRIS' && this.platformHalService.getDeviceModel() === 'VIP4205') {
                        profileSettings.items = profileSettings.items.filter((item => item.key !== 'video-preview'));
                    }
                }
                this.settingsSchemaUpdated.next();
            }));
    }

    public getSettingsSchema(ignoreProfileObjectFields: boolean = false) {
        const portalSettings = this.portalSettingsService.getPortalSettings();

        const customFitler = (items: Array<SettingsItem>) => {
            let res = items;
            if (ignoreProfileObjectFields) {
                res = res.filter(item => profileObjectKeys.indexOf(item.valueKey) === -1);
            }
            if (!portalSettings.mediaPlayer.broadcastGaps) {
                res = res.filter(item => item.key !== 'broadcast-gap-skip-auto');
            }
            return res;
        };
        return this.settingsSchema.map(settingsSection => {
            return {
                ...settingsSection,
                items: customFitler(settingsSection.items)
            };
        });
    }

    public getSettingsSection(key: string, ignoreProfileObjectFields: boolean = false): SettingsSection {
        const settingsSection = { ...this.settingsSchema.find(settingsSectionItem => settingsSectionItem.key === key) };
        const portalSettings = this.portalSettingsService.getPortalSettings();
        if (ignoreProfileObjectFields) {
            settingsSection.items = settingsSection.items
                .filter(item => profileObjectKeys.indexOf(item.valueKey) === -1);
        }
        if (!portalSettings.mediaPlayer.broadcastGaps) {
            settingsSection.items = settingsSection.items.filter(item => item.key !== 'broadcast-gap-skip-auto');
        }
        return settingsSection;
    }

    public getSettingsSubsection(sectionKey: string, subsectionKey: string): SettingsItem {
        const settingsSection = this.getSettingsSection(sectionKey);
        if (settingsSection) {
            return settingsSection.items.find(subsection => subsection.key === subsectionKey);
        }
    }

    public getSettingsTiles(): Array<Tile> {
        return this.settingsSchema.map((settingsSection, index) => {
            return {
                guid: 'settings:' + index,
                label: settingsSection.text,
                section: settingsSection.key,
                image: settingsSection.image ? settingsSection.image.tile : null,
                backdrop: settingsSection.image ? settingsSection.image.backdrop : null,
                type: TileTypes.BUTTON,
                action: Actions.SHOW_SETTINGS
            };
        });
    }

    public moveSettingsLevel(offset) {
        this.activeSettingsLevel = Math.max(this.activeSettingsLevel + offset, 0);
        this.settingsLevelUpdated.next(this.activeSettingsLevel);
    }

    public incSettingsLevel(offset = 1) {
        this.moveSettingsLevel(offset);
    }

    public decSettingsLevel(offset = 1) {
        this.moveSettingsLevel(offset * (-1));
    }

    public clear() {
        this.activeSettingsLevel = 0;
        this.passwordAccepted = false;
        this.clearTempProfileSettings();
    }


    public switchPlatform(payload: { platform: string }) {
        return this.httpClient.post(`${ environment.apiUrl }platform-switch`, payload);
    }

    public getAudioSubOptions(options: Array<SelectOption>, prefLevelMap: Array<string>, value: string) {
        return options.map(option => {
            if (option.value !== value &&
                prefLevelMap.some(key => this.parsedSettings[ key ] === option.value)) {
                return {
                    ...option,
                    disabled: true
                };
            }
            return option;
        });
    }

    public getPlatformSwitch() {
        return this.platformSwitch;
    }

    public isProfileSettingsKey(key: string) {
        return settingsProfileObjectKeys.indexOf(key) >= 0;
    }

    private startPlaformSwitchRefreshInterval() {
        SOM.clearSubscriptions(this.subscription.platformSwitchRefreshInterval);
        this.subscription.platformSwitchRefreshInterval =
            timer(Math.floor(Math.random() * this.PLATFORM_SWITCH_REFRESH_INTERVAL), this.PLATFORM_SWITCH_REFRESH_INTERVAL)
                .pipe(switchMap(() => {
                    return this.fetchPlatformSwitch();
                }))
                .subscribe();
    }

    public destroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
