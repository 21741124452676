import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { MediaPlayerV2Component } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.component';
import { VolumeService } from '@kuki/global/features/volume-v2/volume.service';
import { ModalsInterface } from '@kuki/global/modals/modals.interface';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { Dashboard } from '@kuki/global/sections/dashboard';
import { NpvrService } from '@kuki/global/shared/modules/media/npvr.service';
import { NotificationService } from '@kuki/global/shared/modules/notification/notification.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { CacheMapService } from '@kuki/global/shared/services/cache-map.service';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { MessagingService } from '@kuki/global/shared/services/messaging.service';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { PowerControlService } from '@kuki/global/shared/services/power-control.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { TagService } from '@kuki/global/shared/services/tag.service';
import { CommonKeys } from '@kuki/global/shared/types/controller/keymap';
import { MediaTypes, Tags } from '@kuki/global/shared/types/enum';
import { PubSubMessage } from '@kuki/global/shared/types/general';
import { MediaPlayerConfig } from '@kuki/global/shared/types/media-player';
import { NotificationTypes } from '@kuki/global/shared/types/notification';
import { hal } from '@kuki/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { ArrisPlatformHalService } from '@kuki/platforms/tv/arris/arris-platform-hal.service';
import { AfrService } from '@kuki/platforms/tv/arris/plugins/afr.service';
import { TranslateService } from '@ngx-translate/core';
import { ConnectableObservable, from, Observable, of, timer } from 'rxjs';
import { mergeMap, retryWhen, share, switchMap, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { BroadcastGapsV2Service } from './broadcast-gaps.v2.service';
import { BroadcastGapsV3Service } from './broadcast-gaps.v3.service';
import { ChannelService } from './channel.service';

@Injectable()
export class WSService {

    private readonly SENDING_DEVICE_STATUS_INTERVAL = 30 * 1000;
    private readonly START_BACKOFF_INTERVAL = 1000;
    private readonly MAX_BACKOFF_INTERVAL = 240 * 1000;
    private wsSocket: WebSocketSubject<any>;
    private subscription: SubscriptionObject = {};
    public dataStream$: Observable<PubSubMessage>;

    private refreshRowsTimeout: any;

    constructor(
        private authService: AuthService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private npvrService: NpvrService,
        private tagService: TagService,
        private controllerService: ControllerService,
        private generalService: GeneralService,
        private deviceService: DeviceService,
        private cacheMapService: CacheMapService,
        private ngZone: NgZone,
        private messagingService: MessagingService,
        private powerControlService: PowerControlService,
        private profileService: ProfileService,
        private portalSettingsService: PortalSettingsService,
        private channelService: ChannelService,
        private componentRegisterService: ComponentRegisterService,
        private broadcastGapsV3Service: BroadcastGapsV3Service,
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        @Inject('ModalsService') private modalsService: ModalsInterface,
        @Optional() private volumeService: VolumeService,
        @Optional() private afrService: AfrService
    ) {
    }

    public init(params: { remoteUrl: string, pubSubKey: string, serial: string }) {
        if (this.isInitialized()) {
            this.wsSocket.complete();
            SOM.clearSubscriptionsObject(this.subscription);
        }
        this.wsSocket = webSocket(`${ params.remoteUrl }?key=${ params.pubSubKey }`);
        let backOffInterval = this.START_BACKOFF_INTERVAL;
        this.dataStream$ = this.wsSocket
            .pipe(
                retryWhen((errors: Observable<any>) => errors.pipe(
                    mergeMap((error: any, i: number) => {
                        console.log('Ws error:');
                        console.log(JSON.stringify(error));
                        if (i > 0) {
                            backOffInterval = Math.min(this.MAX_BACKOFF_INTERVAL, backOffInterval * 2);
                        }
                        return timer(backOffInterval);
                    }))),
                tap(() => backOffInterval = this.START_BACKOFF_INTERVAL),
                tap(msg => console.log(JSON.stringify(msg))),
                share()) as ConnectableObservable<PubSubMessage>;

        this.startSendingDeviceStatus(params.serial);
        this.subscription.dataStream = this.dataStream$.subscribe((msg) => {
            if (this.afrService) {
                this.afrService.ping();
            }
            switch (msg.action) {
                case 'recSet':
                    this.addTagFromMessage(msg.entity, Tags.REC);
                    this.npvrService.onRecordChanged$.next(msg.entity.id);
                    break;
                case 'recDelete':
                    this.removeTagFromMessage(msg.entity, Tags.REC);
                    this.npvrService.onRecordChanged$.next(msg.entity.id);
                    break;
                case 'notifySet':
                    this.addTagFromMessage(msg.entity, Tags.NOTIFY);
                    break;
                case 'notifyDelete':
                    this.removeTagFromMessage(msg.entity, Tags.NOTIFY);
                    break;
                case 'favSet':
                    this.addTagFromMessage(msg.entity, Tags.FAVOURITE);
                    break;
                case 'favDelete':
                    this.removeTagFromMessage(msg.entity, Tags.FAVOURITE);
                    break;
                case 'refreshRows':
                    SOM.clearSubscriptions(this.subscription.refreshRows);
                    // DELAY IS necessary
                    this.subscription.refreshRows = this.delayAction(() => of(this.refreshRows(msg.rows)))
                        .subscribe();
                    break;
                case 'refreshDevices':
                    SOM.clearSubscriptions(this.subscription.refreshDevices);
                    // TODO: REMOVE DELAY, imho byt nemusi
                    this.subscription.refreshDevices = this.delayAction(() => this.refreshDevices())
                        .subscribe();
                    break;
                case 'refreshProfiles':
                    SOM.clearSubscriptions(this.subscription.refreshProfiles);
                    // TODO: REMOVE DELAY, imho byt nemusi
                    this.subscription.refreshProfiles = this.delayAction(() => this.refreshProfiles())
                        .subscribe();
                    break;
                case 'refreshProfileSettings':
                    SOM.clearSubscriptions(this.subscription.refreshProfileSettings);
                    // DELAY IS necessary dokud chodi zprava i na zdroj
                    this.subscription.refreshProfileSettings = this.delayAction(() => this.refreshProfileSettings(msg.profileId))
                        .subscribe();
                    break;
                case 'refreshDeviceState':
                    SOM.clearSubscriptions(this.subscription.refreshDeviceState);
                    // TODO: REMOVE DELAY, imho byt nemusi
                    this.subscription.refreshDeviceState = this.delayAction(() => of(this.refreshDeviceState(msg.deviceId)))
                        .subscribe();
                    break;
                case 'refreshPortalSettings':
                    SOM.clearSubscriptions(this.subscription.refreshPortalSettings);
                    // TODO: REMOVE DELAY, imho byt nemusi
                    this.subscription.refreshPortalSettings = this.delayAction(() => this.refreshPortalSettings())
                        .subscribe();
                    break;
                case 'refreshChannels':
                    SOM.clearSubscriptions(this.subscription.refreshChannels);
                    // TODO: REMOVE DELAY, imho byt nemusi, ale tato zprava mi vubec aktualne nechodi
                    this.subscription.refreshChannels = this.delayAction(() => this.refreshChannels())
                        .subscribe();
                    break;
                case 'remote':
                    const mediaPlayerV2Component = this.componentRegisterService
                        .getComponent<MediaPlayerV2Component>('media-player-section');
                    switch (msg.op) {
                        case 'up':
                            this.controllerService.emulatePressByActionKey(CommonKeys.UP);
                            break;
                        case 'down':
                            this.controllerService.emulatePressByActionKey(CommonKeys.DOWN);
                            break;
                        case 'left':
                            this.controllerService.emulatePressByActionKey(CommonKeys.LEFT);
                            break;
                        case 'right':
                            this.controllerService.emulatePressByActionKey(CommonKeys.RIGHT);
                            break;
                        case 'ok':
                            this.controllerService.emulatePressByActionKey(CommonKeys.OK);
                            break;
                        case 'context':
                            this.controllerService.emulatePressByActionKey(CommonKeys.GRP_INFO);
                            break;
                        case 'back':
                            this.controllerService.emulatePressByActionKey(CommonKeys.GRP_BACK);
                            break;
                        case 'keypress':
                            this.controllerService.emulatePress(msg.keycode, msg.keypress_type);
                            break;
                        case 'chup':
                            if (mediaPlayerV2Component) {
                                this.subscription.channelUp = mediaPlayerV2Component.mediaPlayerV2Service.channelUp().subscribe();
                            }
                            break;
                        case 'chdown':
                            if (mediaPlayerV2Component) {
                                this.subscription.channelDown = mediaPlayerV2Component.mediaPlayerV2Service.channelDown().subscribe();
                            }
                            break;
                        case 'volup':
                            if (this.volumeService) {
                                this.volumeService.volUp();
                            }
                            break;
                        case 'voldown':
                            if (this.volumeService) {
                                this.volumeService.volDown();
                            }
                            break;
                        case 'volset':
                            if (this.volumeService) {
                                this.volumeService.setVolume(msg.volume);
                            }
                            break;
                        case 'mute':
                            if (this.volumeService) {
                                this.volumeService.mute();
                            }
                            break;
                        case 'unmute':
                            if (this.volumeService) {
                                this.volumeService.unmute();
                            }
                            break;
                        case 'poweron':
                            this.powerControlService.requestPowerOn();
                            break;
                        case 'poweroff':
                            if (msg.cancelable) {
                                this.confirmBeforeAction(this.translateService.instant('GENERAL.POWER_OFF_CONFIRM')).then(() => {
                                    const stop$ = mediaPlayerV2Component ?
                                        mediaPlayerV2Component.mediaPlayerV2Service.stop() : of(undefined);
                                    stop$.subscribe(() => {
                                        if (this.powerControlService.canPowerOff()) {
                                            this.powerControlService.requestPowerOff();
                                        }
                                    });
                                }).catch(() => {
                                });
                            } else {
                                this.powerControlService.requestPowerOff();
                            }
                            break;
                        case 'setAudio':
                            if (mediaPlayerV2Component) {
                                mediaPlayerV2Component.mediaPlayerV2Service.activateAudioTrackByLang(msg.lang, msg.layout);
                            }
                            break;
                        case 'setSubtitles':
                            if (mediaPlayerV2Component) {
                                mediaPlayerV2Component.mediaPlayerV2Service.activateSubtitleTrackByLang(msg.lang);
                            }
                            break;
                        case 'play':
                            switch (msg.type) {
                                case 'live':
                                    this.playLive(msg);
                                    break;
                                case 'timeshift':
                                    this.playTimeshift(msg);
                                    break;
                                case 'npvr':
                                    this.playNpvr(msg);
                                    break;
                                case 'episode':
                                    this.playEpisode(msg);
                                    break;
                                case 'vod':
                                    this.playVod(msg);
                                    break;
                            }
                            break;
                        case 'seek':
                            if (mediaPlayerV2Component) {
                                const direction = mediaPlayerV2Component.mediaPlayerV2Service.getCursorTime() > msg.position ? -1 : 1;
                                mediaPlayerV2Component.mediaPlayerV2Service.seekToPosition(msg.position, direction).subscribe();
                            }
                            break;
                        case 'pause':
                            if (mediaPlayerV2Component) {
                                mediaPlayerV2Component.mediaPlayerV2Service.pause().subscribe();
                            }
                            break;
                        case 'unpause':
                            if (mediaPlayerV2Component) {
                                mediaPlayerV2Component.mediaPlayerV2Service.unpause().subscribe();
                            }
                            break;
                        case 'showOsd':
                            if (mediaPlayerV2Component) {
                                mediaPlayerV2Component.openOsd();
                            }
                            break;
                        case 'showStb':
                            this.generalService.startDeviceInfo();
                            break;
                        case 'runSelftest':
                            this.generalService.startSelfTest(true);
                            break;
                        case 'teletext':
                            if (mediaPlayerV2Component) {
                                mediaPlayerV2Component.toggleTeletext();
                            }
                            break;
                        case 'startOver':
                            if (mediaPlayerV2Component) {
                                mediaPlayerV2Component.mediaPlayerV2Service.startOver().subscribe();
                            }
                            break;
                    }
                    break;
                case 'noop':
                    break;
                case 'setMaxBitrate':
                    this.generalService.setRemoteMaxBitrate(msg.max_bitrate || 0);
                    break;
                case 'setReqRate':
                    this.generalService.setRemoteReqRate(msg.req_rate || 0);
                    break;
                case 'setLiveChunksBehind':
                    this.generalService.setRemoteLiveChunksBehind(msg.live_chunks_behind || 0);
                    break;
                case 'setMaxLumaSamplesPerSec':
                    this.generalService.setMaxLumaSamplesPerSec(msg.max_luma_samples_per_sec || 0);
                    break;
                case 'updateBroadcastGaps':
                    const broadcastGapMessage = msg as any;
                    this.broadcastGapsV3Service.addBroadcastGap(msg.channel_id, {
                        id: broadcastGapMessage.id,
                        datetimeFrom: broadcastGapMessage.datetime_from,
                        datetimeTo: broadcastGapMessage.datetime_to,
                        type: broadcastGapMessage.type,
                        source: broadcastGapMessage.source,
                        validFrom: broadcastGapMessage.valid_from,
                        validTo: broadcastGapMessage.valid_to,
                        skippable: broadcastGapMessage.skippable
                    });
                    break;
                case 'reboot':
                    if ([ 'CONTRACT_CHANGE', 'CAN_PLAY_CHANGE' ].indexOf(msg.reason) === -1) {
                        this.platformHalService.reboot();
                    }
                    break;
                case 'restart':
                    this.platformHalService.restart();
                    break;
                case 'msg':
                    this.notificationService.show(msg.msg, {
                        type: NotificationTypes.MESSAGE
                    });
                    break;
                case 'new-msg':
                    this.messagingService.fetchNewMessages().subscribe();
                    break;
                case 'updatecachemap':
                    if (hal.platform === 'TV.ARRIS') {
                        this.subscription.updateCacheMap = this.cacheMapService.updateCacheMap().subscribe();
                    }
                    break;
                case 'setTuneler':
                    if (hal.platform === 'TV.ARRIS') {
                        (this.platformHalService as ArrisPlatformHalService).setIsObject('nbx.tunelerdst', msg.tunelerdst);
                        if (msg.tunelerport) {
                            (this.platformHalService as ArrisPlatformHalService).setIsObject('nbx.tunelerport', msg.tunelerport);
                        } else {
                            (this.platformHalService as ArrisPlatformHalService).setIsObject('nbx.tunelerport', '');
                        }
                    }
                    break;
                case 'typeKey':
                    const keyboards = this.componentRegisterService.getComponentsByType<any>('keyboard');
                    const activeKeyboard = keyboards.find(keyboard => keyboard.isActive());
                    if (activeKeyboard) {
                        switch (msg.key) {
                            case 'ok':
                                activeKeyboard.onKeyEnter('OK');
                                break;
                            case 'cancel':
                                activeKeyboard.onKeyBack();
                                break;
                            case 'bcksp':
                                activeKeyboard.onKeyEnter('BCKSP');
                                break;
                            default:
                                activeKeyboard.onKeyEnter(msg.key);
                                break;
                        }
                    }
                    break;
            }
        });
    }

    public isInitialized() {
        return !!this.wsSocket;
    }

    public pub(message) {
        if (!this.wsSocket) {
            return;
        }
        this.wsSocket.next(message);
    }

    public sendPowerChange(serial: string, power: boolean) {
        this.pub({
            state: {
                sn: serial,
                power: power
            }
        });
    }

    // TODO: Remove when replaced with new event system
    private startSendingDeviceStatus(serial) {
        const getAudio = () => {
            if (hal.volume) {
                return {
                    volume: this.volumeService ? this.volumeService.getVolume() : null,
                    mute: this.volumeService ? this.volumeService.getMuted() : null
                };
            }
        };
        this.subscription.timer = timer(
            Math.floor(Math.random() * this.SENDING_DEVICE_STATUS_INTERVAL), this.SENDING_DEVICE_STATUS_INTERVAL)
            .subscribe(() => {
                this.pub({
                    state: {
                        sn: serial,
                        power: !this.powerControlService.isStandBy(),
                        audio: getAudio()
                    }
                });
            });
    }

    private addTagFromMessage(entity: { id: number, mediaType: MediaTypes }, tag: Tags) {
        if (entity) {
            this.tagService.addTag(entity.id, entity.mediaType, tag);
        }
    }

    private removeTagFromMessage(entity: { id: number, mediaType: MediaTypes }, tag: Tags) {
        if (entity) {
            this.tagService.removeTag(entity.id, entity.mediaType, tag);
        }
    }

    private refreshRows(rows: Array<string>) {
        const dashboardComponent: any = this.componentRegisterService.getComponent<Dashboard>('dashboard');
        if (dashboardComponent) {
            dashboardComponent.refreshRows(rows).subscribe();
        }
    }

    private refreshDevices() {
        return this.deviceService.fetchDevices();
    }

    private refreshProfiles() {
        return this.profileService.fetchProfiles();
    }

    private refreshProfileSettings(profileId: number) {
        return this.profileService.refreshProfileSettings(profileId);
    }

    private refreshDeviceState(deviceId: number) {
        this.deviceService.refreshDeviceState(deviceId);
    }

    private refreshPortalSettings() {
        return this.portalSettingsService.fetchPortalSettings();
    }

    private refreshChannels() {
        return this.channelService.fetchChannelList();
    }

    private playLive(msg: PubSubMessage) {
        this.beforePlay(msg)
            .pipe(switchMap(() => this.generalService.playLive(msg.channel_id, this.getMediaPlayerConfig(msg))))
            .subscribe({
                error: () => {
                }
            });
    }

    private playTimeshift(msg: PubSubMessage) {
        this.beforePlay(msg)
            .pipe(switchMap(() => this.generalService.playTimeshift(msg.channel_id, msg.position, this.getMediaPlayerConfig(msg))))
            .subscribe({
                error: () => {
                }
            });
    }

    private playNpvr(msg: PubSubMessage) {
        this.beforePlay(msg)
            .pipe(switchMap(() => this.generalService.playNpvr(msg.epg_entity_id, msg.position, this.getMediaPlayerConfig(msg))))
            .subscribe({
                error: () => {
                }
            });
    }

    private playEpisode(msg: PubSubMessage) {
        this.beforePlay(msg)
            .pipe(switchMap(() => this.generalService.playEpisode(msg.episode_id, msg.position, this.getMediaPlayerConfig(msg))))
            .subscribe({
                error: () => {
                }
            });
    }

    private playVod(msg: PubSubMessage) {
        this.beforePlay(msg)
            .pipe(switchMap(() => this.generalService.playVod(msg.vod_id, msg.position, this.getMediaPlayerConfig(msg))))
            .subscribe({
                error: () => {
                }
            });
    }

    private getMediaPlayerConfig(msg: PubSubMessage): MediaPlayerConfig {
        return {
            audioTrack: msg.audioTrack,
            subtitleTrack: msg.subtitleTrack
        };
    }

    private beforePlay(params: any) {
        if (this.powerControlService.isStandBy()) {
            this.powerControlService.requestPowerOn();
            return timer(3000);
        }
        const device = this.deviceService.getActiveDevice();
        if (params.cancelable && device.confirmTeleport) {
            return from(this.confirmBeforeAction(this.translateService.instant('GENERAL.REMOTE_PLAY_CONFIRM'), 0));
        } else {
            return of(undefined);
        }
    }

    private confirmBeforeAction(message: string, timeout: number = 10000) {
        return this.modalsService.openConfirmModal({
            message: message,
            timeout: timeout
        });
    }

    private delayAction(action: Function, delay: number = 500) {
        return timer(delay).pipe(switchMap(() => action()));
    }
}
