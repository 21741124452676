export enum ImageTypes {
    EPG_ENTITY = 'EPG_ENTITY',
    VOD = 'VOD',
    SERIES = 'SERIES',
    EPISODE = 'EPISODE',
    PERSON_PORTRAIT = 'PERSON_PORTRAIT',
    BUTTON = 'BUTTON',
    CHANNEL_LOGO = 'CHANNEL_LOGO',
    VOD_PROVIDER = 'VOD_PROVIDER',
    PROFILE_IMAGE = 'PROFILE_IMAGE',
    PROFILE_IMAGE_SMALL = 'PROFILE_IMAGE_SMALL',
    SETTINGS = 'SETTINGS',
    DEVICE = 'DEVICE',
    CARROUSEL = 'CARROUSEL',
    FLAG = 'FLAG',
    PROFILE_TYPE = 'PROFILE_TYPE'
}
