import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
    ageLimitOptions,
    aspectOptions,
    channelDisplayFormatOptions,
    dashboardModeOptions,
    dolbyDigitalOptions,
    scaleModeOptions,
    subOptionsCustom,
    unlockTimeoutOptions
} from '@kuki/data/settings/settings.options';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';

import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { PortalSettings } from '@kuki/global/shared/types/general';
import { Settings } from '@kuki/global/shared/types/settings';
import { ArrisPlatformHalService } from '@kuki/platforms/tv/arris/arris-platform-hal.service';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';

@Directive({
    selector: '[appSettingsSelectedItem]'
})
export class SettingsSelectedItemDirective implements OnInit, OnDestroy {

    @Input() appSettingsSelectedItem: string;
    @Input() customData: any;
    @Input() profileId: number;

    private settings: Settings;
    private portalSettings: PortalSettings;

    private subscription: SubscriptionObject = {};

    constructor(
        private settingsService: SettingsService,
        private portalSettingsService: PortalSettingsService,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private translateService: TranslateService,
        private coreService: CoreService,
        private profileService: ProfileService,
        @Inject('PlatformHalService') private arrisPlatformHalService: ArrisPlatformHalService) {
    }

    ngOnInit() {
        this.portalSettings = this.portalSettingsService.getPortalSettings();
        this.subscription.getSettings = this.profileService.getSettings(this.profileId).subscribe((settings) => {
            this.settings = settings;
            this.refresh();
        });
        this.subscription.settingsUpdated = this.settingsService.settingsUpdated$.pipe(switchMap(() => {
            return this.profileService.getSettings(this.profileId);
        })).subscribe(settings => {
            this.settings = settings;
            this.refresh();
        });
        this.subscription.onProfilesFetched = this.profileService.onProfilesFetched$.subscribe(() => {
            this.refresh();
        });
    }

    public refresh() {
        if (!this.settings) {
            return;
        }
        const option = this.getOption(this.settings[ this.appSettingsSelectedItem ]);
        if (option && option.text) {
            if (this.appSettingsSelectedItem !== 'alias') {
                this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', this.translateService.instant(option.text));
            } else {
                this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', (option.text));
            }
        }
    }

    private getOption(settingsValue) {
        if (this.customData) {
            if (this.customData.values) {
                return { text: this.customData.values[ this.appSettingsSelectedItem ] };
            }
            switch (this.appSettingsSelectedItem) {
                case 'alias':
                    return { text: this.customData.alias };
                case 'canPlay':
                    return this.generateBooleanOption(this.customData.canPlay ? '1' : '0');
                case 'canTeleport':
                    return this.generateBooleanOption(this.customData.canTeleport ? '1' : '0');
                case 'confirmTeleport':
                    return this.generateBooleanOption(this.customData.confirmTeleport ? '1' : '0');
                case 'profileType':
                    return { text: this.customData.profileType };
                case 'profileName':
                    return { text: this.customData.profileName };
                case 'activeProfile':
                    return { text: this.customData.activeProfile?.profileName };
            }
        }

        switch (this.appSettingsSelectedItem) {
            case 'nbx.stblang':
                if (this.customData && this.customData.profileLocale) {
                    return this.coreService.getLanguages().find(item => item.value === this.customData.profileLocale);
                }
                return this.coreService.getLanguages().find(item => item.value === settingsValue);
            case 'quickPlay':
                return this.generateBooleanOption(settingsValue);
            case 'infoPanel':
                return this.generateBooleanOption(settingsValue);
            case 'dashboardStartOnChannel':
                return this.generateBooleanOption(settingsValue);
            case 'videoPreview':
                return this.generateBooleanOption(settingsValue);
            case 'channelDisplayFormat':
                return channelDisplayFormatOptions.find(item => item.value === settingsValue);
            case 'dashboardMode':
                return dashboardModeOptions.find(item => item.value === settingsValue);
            case 'animations':
                return this.generateBooleanOption(settingsValue);
            case 'continuingPlay':
                return this.generateBooleanOption(settingsValue);
            case 'bitrate':
                const bitrateOptions = this.portalSettings.mediaPlayer.bitrateRestrictions;
                return bitrateOptions.find(item => item.value.toString() === settingsValue);
            case 'debug':
                return this.generateBooleanOption(settingsValue);
            case 'parentalLock':
                return this.generateBooleanOption(settingsValue);
            case 'unlockTimeout':
                return unlockTimeoutOptions.find(item => item.value === settingsValue);
            case 'ageLimit':
                return ageLimitOptions.find(item => item.value === settingsValue);
            case 'aspect':
                return aspectOptions.find(item => item.value === settingsValue);
            case 'scaleMode':
                return scaleModeOptions.find(item => item.value === settingsValue);
            case 'dolbyDigital':
                return dolbyDigitalOptions.find(item => item.value === settingsValue);
            case 'hdmiStbOff':
                return this.generateBooleanOption(settingsValue);
            case 'hdmiTvOff':
                return this.generateBooleanOption(settingsValue);
            case 'videoMode':
                return this.arrisPlatformHalService.getVideoModeText ?
                    { text: this.arrisPlatformHalService.getVideoModeText(settingsValue) } : null;
            case 'screenSaver':
                return this.generateBooleanOption(settingsValue);
            case 'broadcastGapSkipAuto':
                return this.generateBooleanOption(settingsValue);
        }

        if ([ 'audioPrefLevel1', 'audioPrefLevel2', 'audioPrefLevel3' ].indexOf(this.appSettingsSelectedItem) >= 0) {
            const audioOptions = this.portalSettings.mediaPlayer.languagesAudio;
            return audioOptions.find(item => item.value === settingsValue);
        } else if ([ 'subPrefLevel1', 'subPrefLevel2', 'subPrefLevel3' ].indexOf(this.appSettingsSelectedItem) >= 0) {
            const subOptions = [ ...subOptionsCustom, ...this.portalSettings.mediaPlayer.languagesSubtitles ];
            return subOptions.find(item => item.value === settingsValue);
        }
    }

    private generateBooleanOption(value) {
        return value === '1' ? { text: 'Ano' } : { text: 'Ne' };
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
