import { ElementRef, Inject, Injectable, NgZone } from '@angular/core';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { environment } from '@kuki/environments/environment';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { from, fromEvent, Observable, of, timer } from 'rxjs';
import { hal } from '@kuki/root/platforms/hal';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { DeviceInfo } from '@kuki/global/shared/types/device';
import { map, tap, throttleTime } from 'rxjs/operators';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { SelfTestComponent } from '@kuki/global/shared/modules/self-test/self-test.component';
import { MediaPlayerV2Component } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.component';
import { Actions } from '@kuki/global/shared/types/enum';
import { Language } from '@kuki/global/shared/types/general';
import { HttpClient } from '@angular/common/http';
import { MediaPlayerConfig } from '@kuki/global/shared/types/media-player';

declare var webapis;

@Injectable()
export class GeneralService {

    public transformFunction = hal.platform === 'TV.ARRIS' ? '-webkit-transform' : 'transform';
    public transitionFunction = hal.platform === 'TV.ARRIS' ? '-webkit-transition' : 'transition';

    private activeScrolling;
    private subscription: SubscriptionObject = {};

    private remoteMaxBitrate: number;
    private remoteReqRate: number;
    private remoteLiveChunksBehind: number;
    private remoteMaxLumaSamplesPerSec: number;

    public scroll$: Observable<number> = fromEvent<WheelEvent>(document, 'wheel').pipe(
        throttleTime(100),
        map(event => event.deltaY)
    );


    public easingLogic = {
        ease: (t: number, b: number, c: number, d: number): number => {
            // Linear easing
            return c * t / d + b;
        }
    };

    private tizenVersionYear: number;
    private languages: Array<Language>;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private navigationService: NavigationService,
        private componentRegisterService: ComponentRegisterService,
        @Inject('PlatformHalService') private platformHalService: PlatformHal,
        private ngZone: NgZone) {
    }

    public getDeviceInfo(): DeviceInfo {
        return {
            mac: this.platformHalService.getMac ? this.platformHalService.getMac() : null,
            ip: this.platformHalService.getIp ? this.platformHalService.getIp() : null,
            publicIp: this.authService.getAuthData().public_ip,
            sn: this.authService.serial,
            version: environment.version,
            buildVersion: environment.versionBuild,
            fwVersion: this.platformHalService.getVersionFw(),
            deviceType: hal.deviceType
        };
    }

    public focusEl(element: ElementRef<HTMLElement>) {
        const x = window.scrollX;
        const y = window.scrollY;
        element.nativeElement.focus();
        window.scrollTo(x, y);
    }

    public getScrollTop() {
        return this.navigationService.getScrollTop();
    }

    public scrollTo(position: number, mode = hal.animationType): Promise<void> {
        switch (mode) {
            case 'anim':
            case 'angular-anim':
            case 'request-animation-frame':
            case 'css-transition':
            case 'js-timeout':
                return this.scrollToAnim(position);
            case 'fixed':
            case 'fixed-abs':
                return this.scrollToFixed(position);
            case 'noop':
                return Promise.resolve();
        }
    }

    public scrollToAnim(position: number, duration: number = 200, frameRate: number = 16.7): Promise<void> {
        return new Promise((resolve) => {
            SOM.clearSubscriptions(this.subscription.scrollAnimation);
            // if (this.activeScrolling !== null) {
            //     window.scrollTo(0, this.activeScrolling);
            //     this.activeScrolling = null;
            // }
            const doc = document.documentElement;
            const scrollTop = Math.ceil((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0));
            this.activeScrolling = position;
            const startTime = Date.now();
            if (scrollTop < position) {
                this.ngZone.runOutsideAngular(() => {
                    this.subscription.scrollAnimation = timer(0, frameRate).subscribe(() => {
                        const newScrollPosition = this.easingLogic.ease(
                            Date.now() - startTime,
                            scrollTop,
                            position - scrollTop,
                            duration
                        );
                        if (newScrollPosition >= position) {
                            window.scrollTo(0, position);
                            this.activeScrolling = null;
                            SOM.clearSubscriptions(this.subscription.scrollAnimation);
                            resolve();
                        } else {
                            window.scrollTo(0, newScrollPosition);
                        }
                    });
                });
            } else {
                this.ngZone.runOutsideAngular(() => {
                    this.subscription.scrollAnimation = timer(0, frameRate).subscribe(() => {
                        const newScrollPosition = this.easingLogic.ease(
                            Date.now() - startTime,
                            scrollTop,
                            position - scrollTop,
                            duration
                        );
                        if (newScrollPosition <= position) {
                            window.scrollTo(0, position);
                            this.activeScrolling = null;
                            SOM.clearSubscriptions(this.subscription.scrollAnimation);
                            resolve();
                        } else {
                            window.scrollTo(0, newScrollPosition);
                        }
                    });
                });
            }
        });
    }

    public scrollToFixed(position: number): Promise<void> {
        return new Promise((resolve) => {
            const doc = document.documentElement;
            const scrollTop = Math.ceil((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0));
            if (scrollTop < position) {
                window.scrollTo(0, position);
            } else {
                window.scrollTo(0, position);
            }
            resolve();
        });
    }

    public startSelfTest(returnBack: boolean = false) {
        const selfTest = this.componentRegisterService.getComponent<SelfTestComponent>('self-test');
        // rerun self test if already in self test section
        if (selfTest) {
            selfTest.clear();
            selfTest.start();
        } else {
            this.navigationService.navigateSelfTest(returnBack);
        }
    }

    public startDeviceInfo() {
        this.navigationService.navigateDeviceInfo();
    }

    public playLive(channelId: any, config?: MediaPlayerConfig): Observable<any> {
        if (!channelId) {
            return of(null);
        }
        const mediaPlayerV2Component = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
        if (mediaPlayerV2Component) {
            config.refreshTracks = true;
            return mediaPlayerV2Component.mediaPlayerV2Service.playLive(channelId, config);
        }
        return from(this.navigationService.navigateOnAction(Actions.PLAY, {
            mediaPlayerType: 'channel',
            id: channelId,
            params: {
                type: 'live',
                ...this.prepareMediaPlayerNavParams(config)
            }
        }));
    }

    public playTimeshift(channelId: any, position: number, config?: MediaPlayerConfig): Observable<any> {
        if (!channelId) {
            return of(null);
        }
        const mediaPlayerV2Component = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
        if (mediaPlayerV2Component) {
            config.refreshTracks = true;
            return mediaPlayerV2Component.mediaPlayerV2Service.playTs(channelId, position, config);
        }
        const mediaPlayerNavData: any = {
            mediaPlayerType: 'channel',
            id: channelId,
            params: {
                type: 'ts',
                start: position,
                ...this.prepareMediaPlayerNavParams(config)
            }
        };
        return from(this.navigationService.navigateOnAction(Actions.PLAY, mediaPlayerNavData));
    }

    public playNpvr(epgEntityId: any, position: number, config?: MediaPlayerConfig): Observable<any> {
        if (!epgEntityId) {
            return of(null);
        }
        const mediaPlayerV2Component = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
        if (mediaPlayerV2Component) {
            config.refreshTracks = true;
            return mediaPlayerV2Component.mediaPlayerV2Service.playNpvr(epgEntityId, position, true, config);
        }
        const mediaPlayerNavData: any = {
            mediaPlayerType: 'npvr',
            id: epgEntityId,
            params: {
                start: position || 0,
                absolute: 1,
                ...this.prepareMediaPlayerNavParams(config)
            },
        };
        return from(this.navigationService.navigateOnAction(Actions.PLAY, mediaPlayerNavData));
    }

    public playEpisode(episodeId: number, position: number, config?: MediaPlayerConfig): Observable<any> {
        if (!episodeId) {
            return of(null);
        }
        const mediaPlayerV2Component = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
        if (mediaPlayerV2Component) {
            config.refreshTracks = true;
            return mediaPlayerV2Component.mediaPlayerV2Service.playEpisode(episodeId, position, false, config);
        }
        const mediaPlayerNavData: any = {
            mediaPlayerType: 'episode',
            id: episodeId,
            params: {
                start: position || 0,
                ...this.prepareMediaPlayerNavParams(config)
            }
        };
        return from(this.navigationService.navigateOnAction(Actions.PLAY, mediaPlayerNavData));
    }

    public playVod(vodId: number, position: number, config?: MediaPlayerConfig): Observable<any> {
        if (!vodId) {
            return of(null);
        }
        const mediaPlayerV2Component = this.componentRegisterService.getComponent<MediaPlayerV2Component>('media-player-section');
        if (mediaPlayerV2Component) {
            config.refreshTracks = true;
            return mediaPlayerV2Component.mediaPlayerV2Service.playVod(vodId, position, config);
        }
        const mediaPlayerNavData: any = {
            mediaPlayerType: 'vod',
            id: vodId,
            params: {
                start: position || 0,
                ...this.prepareMediaPlayerNavParams(config)
            }
        };
        return from(this.navigationService.navigateOnAction(Actions.PLAY, mediaPlayerNavData));
    }

    public setRemoteMaxBitrate(maxBitRate) {
        this.remoteMaxBitrate = maxBitRate;
    }

    public setRemoteReqRate(reqRate) {
        this.remoteReqRate = reqRate;
    }

    public setRemoteLiveChunksBehind(liveChunksBehind) {
        this.remoteLiveChunksBehind = liveChunksBehind;
    }

    public setMaxLumaSamplesPerSec(maxLumaSamplesPerSec) {
        this.remoteMaxLumaSamplesPerSec = maxLumaSamplesPerSec;
    }

    public getRemoteUrl() {
        if (hal.platform === 'TV.ARRIS' || this.isTizen2015()) {
            return this.authService.getAuthData().remote_url_stb;
        }
        return this.authService.getAuthData().remote_url;
    }

    public isTizen2015() {
        if (this.tizenVersionYear === undefined) {
            this.parseTizenVersion();
        }
        return this.tizenVersionYear === 2015;
    }

    public isTizen2016() {
        if (this.tizenVersionYear === undefined) {
            this.parseTizenVersion();
        }
        return this.tizenVersionYear === 2016;
    }

    public fetchLanguages() {
        return this.httpClient.get<Array<Language>>(environment.apiUrl + 'languages')
            .pipe(
                tap((languages) => {
                    this.languages = languages;
                })
            );
    }

    public getLanguages() {
        return this.languages;
    }

    private prepareMediaPlayerNavParams(params) {
        const mediaPlayerNavParams = {};
        if (params[ 'audioTrack' ]) {
            mediaPlayerNavParams[ 'audioTrackLang' ] = params[ 'audioTrack' ].language;
            mediaPlayerNavParams[ 'audioTrackLayout' ] = params[ 'audioTrack' ].layout;
        }
        if (params[ 'subtitleTrack' ]) {
            mediaPlayerNavParams[ 'subtitleTrackLang' ] = params[ 'subtitleTrack' ].language;
        }
        return mediaPlayerNavParams;
    }

    private parseTizenVersion() {
        if (hal.platform === 'TV.TIZEN' && typeof (webapis) !== 'undefined') {
            const dm: string = this.platformHalService.getDeviceModel();
            // map
            const yearMap = {
                J: 2015,
                K: 2016
            };
            if (dm && dm.length >= 5) {
                this.tizenVersionYear = yearMap[ dm[ 4 ] ];
                return;
            }
        }
        // undetected year, for newer models we dont need year
        this.tizenVersionYear = 0;
    }
}
