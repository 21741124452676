import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ImageLinkFactoryService } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.service';
import { ImageTypes, MediaTypes } from '@kuki/global/shared/types/enum';

@Component({
    selector: 'app-image-link-factory',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageLinkFactoryComponent implements OnInit, OnChanges {

    @Input() urlDefault: string;
    @Input() type = 'tile';
    @Input() imageType: ImageTypes;
    @Input() local: boolean;
    @Input() url: string;
    @Input() mediaType: MediaTypes;

    private generatedUrl: string;

    constructor(private renderer: Renderer2,
                private elementRef: ElementRef,
                private imageLinkFactoryService: ImageLinkFactoryService) {
    }

    ngOnInit() {
        this.renderer.addClass(this.elementRef.nativeElement, 'image-link-factory');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('mediaType')) {
            this.imageType = this.imageLinkFactoryService.getImageTypeFromMediaType(this.mediaType);
        }
        if (changes.hasOwnProperty('url')) {
            this.generateImage();
        }
    }

    private generateImage() {
        if (!this.imageType) {
            return;
        }
        this.generatedUrl = this.imageLinkFactoryService.generateUrl(this.imageType, this.type, this.url, this.urlDefault, this.local);
        if (!this.generatedUrl || !this.elementRef) {
            return;
        }
        // this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', 'none');
        // this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', `url(${ this.generatedUrl })`);
        // const image = new Image();
        // image.src = this.generatedUrl;
        // image.onload = () => {
        this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', `url(${ this.generatedUrl })`);
        // };
    }
}
